import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import {
  applySavingToReference,
  restoreListToReference,
  selectElementFormList,
  selectSlideElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ProcessorBatchDetailsView from '@components/views/processor-batch/ProcessorBatchDetailsView';
import { ProductImageForm } from '@components/views/product/ProductDetailsView';
import { ProductForm } from '@components/views/product/ProductRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { resetProcessorBatchList } from '@redux/features/processor-batch/processorBatchSlice';
import { setIncomingProcessorShippingNotePage } from '@redux/features/processor-shipping-note/incomingProcessorShippingNoteSlice';
import {
  resetCompanyProductTypeList,
  setCompanyProductTypeList
} from '@redux/features/product-type/companyProductTypeSlice';
import { resetProductList, setProductList, setProductListSort } from '@redux/features/product/productSlice';
import { resetCompanySurfaceList, setCompanySurfaceList } from '@redux/features/surface/companySurfaceSlice';
import { RootState } from '@redux/store';
import {
  customCompanyProductTypeResourceApi,
  customCompanyResourceApi,
  customCompanySurfaceResourceApi,
  customProcessorBatchResourceApi,
  customProductResourceApi,
  listSize
} from '@services/apis/ApiConfiguration';
import {
  Company,
  CompanyProductType,
  CompanySurface,
  PageCompany,
  ProcessorBatch,
  ProcessorBatchCurrentStateEnum,
  ProcessorBatchDTO,
  ProcessorShippingNoteDTO,
  ProcessorShippingNoteDTOCurrentStateEnum,
  Product,
  ProductCertification,
  ProductCertificationCertificationEnum,
  ProductCurrentStateEnum,
  ProductDTO,
  ProductSpecies,
  ProductSpeciesSpeciesEnum
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { getTextByTreeOrTrunkSpecies } from '../../utils/classes/UrstammUtilityCurrentState';
import {
  generateSet,
  generateSetForCertification,
  generateSetForProductSpecies,
  processorBatchIsClosed,
  sleep
} from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function ProcessorBatchDetailsContainer({
  navigation,
  route
}: RootStackScreenProps<'ProcessorBatchDetails'>) {
  const rdxCompanySurfaceList = useSelector((state: RootState) => state.persistedReducer.companySurface.list);
  const rdxCompanySurfaceCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companySurface.completeList
  );
  const rdxProcessorShippingNoteList = useSelector(
    (state: RootState) => state.persistedReducer.incomingProcessorShippingNote.list
  );
  const rdxProcessorShippingNoteCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.incomingProcessorShippingNote.completeList
  );
  const rdxProcessorShippingNotePage = useSelector(
    (state: RootState) => state.persistedReducer.incomingProcessorShippingNote.page
  );
  const rdxProcessorShippingNoteTotalPages = useSelector(
    (state: RootState) => state.persistedReducer.incomingProcessorShippingNote.totalPages
  );
  const rdxProcessorShippingNoteSortBy = useSelector(
    (state: RootState) => state.persistedReducer.incomingProcessorShippingNote.sortBy
  );
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const rdxMyCompany = useSelector((state: RootState) => state.persistedReducer.user.myCompany);
  const rdxCompanyProductTypeList = useSelector((state: RootState) => state.persistedReducer.companyProductType.list);
  const rdxCompanyAppearanceList = useSelector((state: RootState) => state.persistedReducer.companyAppearance.list);

  const rdxProductList = useSelector((state: RootState) => state.persistedReducer.product.list);
  const rdxProductCompleteList = useSelector((state: RootState) => state.persistedReducer.product.completeList);
  const dispatch = useDispatch();

  const [processorList, setProcessorList] = useState<Company[]>([]);
  const [processorBatchList, setProcessorBatchList] = useStateWithCallbackLazy<ProcessorBatch[]>([]);
  const [processorBatchSelected, setProcessorBatchSelected] = useState<ProcessorBatch>();
  const [processorBatchDTO, setProcessorBatchDTO] = useStateWithCallbackLazy<any>(() => {});
  const [processorBatchUniqueIdentifier, setProcessorBatchUniqueIdentifier] = useState<string>('');
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [sourceProductList, setSourceProductList] = useStateWithCallbackLazy<any>(() => {});
  const [sourceProductListReference, setSourceProductListReference] = useState<any>();
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  const [productSelected, setProductSelected] = useState<ProductDTO>();
  const [productSpeciesList, setProductSpeciesList] = useStateWithCallbackLazy<any[]>([]);
  const [productSpeciesListReference, setProductSpeciesListReference] = useStateWithCallbackLazy<any[]>([]);
  const [certificationList, setCertificationList] = useStateWithCallbackLazy<any[]>([]);
  const [certificationListReference, setCertificationListReference] = useStateWithCallbackLazy<any[]>([]);
  const [closeModalClosingProduct, setCloseModalClosingProduct] = useState<boolean>(false);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: !processorBatchIsClosed(route.params?.processorBatchSelected),
      text: i18n.t('generics.product'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    if (route.params?.processorBatchSelected) {
      setProcessorBatchSelected(route.params?.processorBatchSelected);
      getProcessorBatchDTOByProcessorBatchId(route.params?.processorBatchSelected);
      getAllProductByProcessorBatchId(route.params?.processorBatchSelected);
    }
  }, [route.params?.processorBatchSelected]);

  useEffect(() => {
    getAllCompanyProcessorConnectedToMe();
  }, []);

  const getProcessorBatchDTOByProcessorBatchId = (processorBatch: ProcessorBatch) => {
    customProcessorBatchResourceApi
      .getProcessorBatchCustom({ id: processorBatch.id! })
      .then((processorBatchDTO: ProcessorBatchDTO) => {
        if (processorBatchDTO)
          setProcessorBatchDTO(processorBatchDTO, updatedProcessorBatchDTO => {
            rdxProcessorShippingNoteCompleteList.length > 0
              ? copyProcessorShippingNoteObj(updatedProcessorBatchDTO)
              : {};
          });
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const copyProcessorShippingNoteObj = (processorBatchDTO: ProcessorBatchDTO) => {
    let list: ProcessorShippingNoteDTO[] = JSON.parse(JSON.stringify(rdxProcessorShippingNoteCompleteList));

    //TODO: ProcessorShippingNote with trunks?
    //Filtering ProcessorShippingNote by processorBatch recording type
    list = list.filter(
      elem =>
        // same recording type
        elem.outgoingBatch?.recordingType == processorBatchDTO.recordingType &&
        // is there something
        elem.incomingProducts?.length &&
        elem.incomingProducts?.length > 0 &&
        // is accepted
        elem.currentState == ProcessorShippingNoteDTOCurrentStateEnum.Accepted
    );

    // console.log('list, ', list);

    let incomingProducts = list.map(
      shippingNoteItem =>
        shippingNoteItem.incomingProducts?.map(p => ({
          ...p,
          // name: shippingNoteItem.name,
          // processorBatch: {
          //   name: p.processorBatch?.name
          // },
          // _acceptedProcessorShippingNote: shippingNoteItem,
          _acceptedProcessorShippingNote: {
            id: shippingNoteItem?.id,
            creator: {
              name: shippingNoteItem?.creator?.name
            }
          }
        })) || []
    );
    // .reduce((prev, next) => [...prev, ...next], [] as Product[]);

    incomingProducts = [].concat.apply([], incomingProducts as any);
    // console.log(flattened); // [1, 2, 3, 4, 5, 6]

    // console.log('solved....', incomingProducts);
    // console.log('two....', flattened);

    // removes duplicate
    incomingProducts = incomingProducts
      .filter((ip: any, index, array) => {
        return !array.slice(index + 1).find((p: any) => p.id === ip.id);
      })
      .filter((p: any) => !p.project);

    incomingProducts = incomingProducts.map(val => ({
      ...val,
      selected: false
    }));

    // console.log('final data', incomingProducts);

    setSourceProductSelected(processorBatchDTO, incomingProducts);
  };

  /**
   *
   * @param processorBatchDTO
   * @param list with custom value (selected: boolean)
   */
  const setSourceProductSelected = (processorBatchDTO: ProcessorBatchDTO, list) => {
    let sourceProductSelected: Product[] = JSON.parse(
      JSON.stringify(Array.from(processorBatchDTO.sourceProducts || new Set()))
    ); //Copy Product (sourceProduct) from processorBatchDTO (Set)
    if (sourceProductSelected.length > 0) {
      let copyList = JSON.parse(JSON.stringify(list));
      sourceProductSelected.forEach((sourceProductSel: Product) => {
        copyList = selectSourceProduct(sourceProductSel, copyList, true);
      });
      //if i haven't ProcessorShippingNotes saved in the processorBatch I set the default list with (selected: false)
    } else {
      setSourceProductList(list, updatedSourceProductList => {});
      setSourceProductListReference(list);
    }
  };

  const selectSourceProduct = (data, list, setReference?: boolean) => {
    let listCopy = JSON.parse(JSON.stringify(list));
    listCopy.forEach(val => {
      if (val.id == data.id) {
        val.selected = !data.selected;
      }
    });
    setSourceProductList(listCopy, updatedSourceProductList => {});
    setReference ? setSourceProductListReference(listCopy) : null;
    return listCopy;
  };

  /**
   * When user closes modal I restore the previous list (using the reference)
   */
  const restoreSourceProductList = () => {
    const cloneList = JSON.parse(JSON.stringify(sourceProductListReference));
    setSourceProductList(cloneList, () => {});
  };

  const updateProcessorBatch = (processorBatch: ProcessorBatchDTO, closingProcessorBatch?: boolean) => {
    let sourceProductSet: Set<Product> = new Set(generateSet(sourceProductList));

    dispatch(changeLoaderStatus(true));

    let processorBatchObj: ProcessorBatch = {
      id: processorBatchSelected?.id!,
      name: processorBatch.name!,
      uniqueIdentifier: processorBatchSelected?.uniqueIdentifier!,
      creationDate: processorBatch.creationDate,
      currentState: closingProcessorBatch
        ? ProcessorBatchCurrentStateEnum.Closed
        : ProcessorBatchCurrentStateEnum.InProgress,
      recordingType: processorBatchSelected?.recordingType! as any,
      comment: processorBatch.comment!,
      sourceProducts: sourceProductSet
    };

    customProcessorBatchResourceApi
      .updateProcessorBatchCustom({ id: processorBatchSelected?.id!, processorBatch: processorBatchObj })
      .then((processorBatch: ProcessorBatch) => {
        dispatch(changeLoaderStatus(false));
        if (processorBatch) {
          dispatch(resetProcessorBatchList());
          UrstammNavigationHelper.navigateToProcessorBatchList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const goBack = async (): Promise<void> => {
    dispatch(resetProcessorBatchList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToProcessorBatchList(navigation, true);
  };

  const getMoreSourceProductItems = (): void => {
    if (rdxProcessorShippingNotePage < rdxProcessorShippingNoteTotalPages - 1) {
      dispatch(setIncomingProcessorShippingNotePage(rdxProcessorShippingNotePage + 1));
    }
  };

  const validateProduct = async (data: Product) => {
    getProductCustom(data);
  };

  const getProductCustom = (productSelected: Product) => {
    dispatch(changeLoaderStatus(true));

    customProductResourceApi
      .getProductCustom({ id: productSelected.id! })
      .then((product: ProductDTO) => {
        dispatch(changeLoaderStatus(false));
        if (product) {
          let incomingProductsSelected = JSON.parse(
            JSON.stringify((processorBatchSelected?.sourceProducts || []) ?? [])
          );
          setProductSelected(product);
          getAllProductCertifications(product);
          getAllProductSpecies(product);
          getCompanySurfaceResourceApi();
          getCompanyProductType();
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanySurfaceResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanySurfaceResourceApi
      .getAllCompanySurfacesForCompany({})
      .then((surfaceList: CompanySurface[]) => {
        dispatch(changeLoaderStatus(false));

        if (surfaceList) {
          dispatch(resetCompanySurfaceList());
          dispatch(setCompanySurfaceList(surfaceList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setProductListSort(orderSelected));
    setShowSortBy(false);
  };

  const getAllProductByProcessorBatchId = (processorBatchSelected: ProcessorBatch) => {
    dispatch(changeLoaderStatus(true));

    customProductResourceApi
      .getProductsByBatch({ batchId: processorBatchSelected?.id! })
      .then((productList: Product[]) => {
        // force keep productSpecies and productCertifications
        return productList.map(p => {
          p.productCertifications = [...(p.productCertifications?.values() || [])] as any;
          p.productSpecies = [...(p.productSpecies?.values() || [])] as any;
          return p;
        });
      })
      .then((productList: Product[]) => {
        dispatch(changeLoaderStatus(false));
        if (productList) {
          dispatch(resetProductList());
          dispatch(setProductList(productList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const navigateToPrefilledProductRegistration = (product: Product | ProductDTO) => {
    UrstammNavigationHelper.navigateToProductRegistration(navigation, processorBatchDTO, product);
  };

  const navigateToProductRegistration = () => {
    UrstammNavigationHelper.navigateToProductRegistration(navigation, processorBatchDTO);
  };

  const navigateToProductDetails = (productSelected: Product) => {
    UrstammNavigationHelper.navigateToProductDetails(navigation, processorBatchDTO, productSelected);
  };

  /**
   * Setting processor list, it used for "Registration" and "Detail" Pages.
   * @param order
   */
  const getAllCompanyProcessorConnectedToMe = (order?: ButtonSelect[]) => {
    let sortBy = order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['name,desc'];
    dispatch(changeLoaderStatus(true));

    customCompanyResourceApi
      .getCompaniesConnectedToMe({
        sort: sortBy,
        customCompanyCriteria: {},
        page: 0,
        size: listSize
      })
      .then((list: PageCompany) => {
        dispatch(changeLoaderStatus(false));

        if (list && list.content?.length) {
          list.content.push({ name: i18n.t('views.product.no_urstamm_processor'), id: -200 });
          setProcessorList(list.content);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getAllProductCertifications = (product: ProductDTO | Product) => {
    let filterList: any[] = [];
    Object.values(ProductCertificationCertificationEnum).map(state => {
      let type: any = {
        id: state,
        name: state,
        selected: state == ProductCertificationCertificationEnum.Urstamm,
        disabled: state == ProductCertificationCertificationEnum.Urstamm
      };
      filterList.push(type);
    });

    let productCertification = product.productCertifications ? [...product.productCertifications] : [];
    if (productCertification && productCertification.length > 0) {
      productCertification.forEach(elemSelected => {
        for (let val of filterList) {
          if (val.id == elemSelected.certification) {
            val.selected = true;
            break;
          }
        }
      });
    }
    setCertificationList(filterList, () => {});
    setCertificationListReference(filterList, () => {});
  };

  const getAllProductSpecies = (product: ProductDTO | Product) => {
    let productSpecies = product.productSpecies ? [...product.productSpecies] : [];

    let filterList: any[] = Object.values(ProductSpeciesSpeciesEnum).map(state => ({
      id: state,
      name: state,
      text: getTextByTreeOrTrunkSpecies(state),
      selected: !!productSpecies.find(p => p.species === state),
      active: !!productSpecies.find(p => p.species === state),
      disabled: false
    }));

    setProductSpeciesList(filterList, () => {});
    setProductSpeciesListReference(filterList, () => {});
  };

  const getCompanyProductType = () => {
    dispatch(changeLoaderStatus(true));

    customCompanyProductTypeResourceApi
      .getAllCompanyProductTypesForCompany({})
      .then((productTypeList: CompanyProductType[]) => {
        dispatch(changeLoaderStatus(false));

        if (productTypeList) {
          dispatch(resetCompanyProductTypeList());
          dispatch(setCompanyProductTypeList(productTypeList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const closingProduct = (productForm: ProductForm, productImageForm: ProductImageForm | undefined) => {
    dispatch(changeLoaderStatus(true));

    let certificationSet: Set<ProductCertification> = new Set(generateSetForCertification(certificationList));
    let speciesSet: Set<ProductSpecies> = generateSetForProductSpecies(productSpeciesList);

    let productObj: Product = {
      id: productSelected?.id,
      processorBatch: processorBatchSelected as any,
      currentState: ProductCurrentStateEnum.Closed,
      uniqueIdentifier: productForm.product.uniqueIdentifier!,
      name: productForm.product.name!,
      creationDate: productForm.product.creationDate,
      comment: productForm.product.comment,
      processor: productForm.product.processorCustomName ? undefined : productForm.product.processor,
      processorCustomName:
        productForm.product.processorCustomName && productForm.product.processorCustomName.length > 0
          ? productForm.product.processorCustomName
          : undefined,
      sumCubage: productForm.product.sumCubage,
      productCertifications: certificationSet,
      productSpecies: speciesSet,
      productType: productForm.product.productType,
      surface: productForm.product.surface,
      longitude: isNaN(Number(productImageForm?.longitude)) ? undefined : Number(productImageForm?.longitude),
      latitude: isNaN(Number(productImageForm?.latitude)) ? undefined : Number(productImageForm?.latitude),
      appearance: productForm.product.appearance,
      photoCreationDate: productImageForm?.photoCreationDate
    };

    customProductResourceApi
      .updateProductCustom({
        id: productSelected?.id!,
        entityWithFileDTOProduct: {
          entity: productObj,
          base64File: productImageForm?.base64File
        }
      })
      .then(async (product: Product) => {
        dispatch(changeLoaderStatus(false));

        if (product) {
          getProcessorBatchDTOByProcessorBatchId(processorBatchSelected!);
          setCloseModalClosingProduct(true);
          setCloseModalClosingProduct(false);
          getAllProductByProcessorBatchId(processorBatchDTO);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      submitBottomRight={navigateToProductRegistration}
      title={
        <UrstammTitle
          testID={'processorBatch_title'}
          text={i18n.t('generics.processor_batch')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'trunk_details_subtitle'}
          text={processorBatchSelected?.uniqueIdentifier ? processorBatchSelected?.uniqueIdentifier : ''}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={[{ title: i18n.t('generics.name'), value: processorBatchSelected?.name!, truncateValue: 30 }]}
      view={
        <ProcessorBatchDetailsView
          navigation={navigation}
          processorBatchSelectedDTO={processorBatchDTO!}
          customProcessorShippingNoteList={sourceProductList}
          moreItems={getMoreSourceProductItems}
          userExtendedMe={rdxUserExtendedMe}
          processorShippingNoteSelected={sourceProductSelected =>
            selectSourceProduct(sourceProductSelected.item, sourceProductList, false)
          }
          updateProcessorBatch={(processorBatchDTO: ProcessorBatchDTO) => updateProcessorBatch(processorBatchDTO)}
          resetProcessorShippingNoteSelected={restoreSourceProductList}
          confirmProcessorShippingNoteSelected={() =>
            applySavingToReference(setSourceProductListReference, sourceProductList)
          }
          productList={rdxProductList}
          showSortBy={showSortBy}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
          closeShowSortBy={() => setShowSortBy(false)}
          validate={(data: Product) => validateProduct(data)}
          productSelected={navigateToProductDetails}
          processorList={processorList}
          companyProductTypeProps={{
            companyProductTypeList: rdxCompanyProductTypeList
          }}
          companyAppearanceProps={{
            companyAppearanceList: rdxCompanyAppearanceList
          }}
          certificationProps={{
            certificationList: certificationList,
            certificationSelected: (data: any) => selectElementFormList(data, setCertificationList, certificationList),
            resetCertificationSelected: () => restoreListToReference(setCertificationList, certificationListReference),
            confirmCertificationSelected: () => applySavingToReference(setCertificationListReference, certificationList)
          }}
          speciesProps={{
            speciesList: productSpeciesList,
            speciesSelected: (data: any) => selectSlideElementFormList(data, setProductSpeciesList, productSpeciesList),
            resetSpeciesSelected: () => restoreListToReference(setProductSpeciesList, productSpeciesListReference),
            confirmSpeciesSelected: () => applySavingToReference(setProductSpeciesListReference, productSpeciesList)
          }}
          companySurfaceProps={{
            companySurfaceList: rdxCompanySurfaceList
          }}
          submitProductForm={(productForm: ProductForm, productImageForm: ProductImageForm | undefined) =>
            closingProduct(productForm, productImageForm!)
          }
          closeModalClosingProduct={closeModalClosingProduct}
          closingProcessorBatch={(processorBatchDTO: ProcessorBatchDTO) =>
            updateProcessorBatch(processorBatchDTO, true)
          }
          cloneProduct={navigateToPrefilledProductRegistration}
        />
      }
    />
  );
}
