import UrstammImageDone from '@components/images/state/UrstammImageDone';
import UrstammImageInProgress from '@components/images/state/UrstammImageInProgress';
import { i18n } from '@i18n/i18n';
import { de } from '@i18n/i18n_de';
import {
  BatchCurrentStateEnum,
  BatchDTOCurrentStateEnum,
  BatchDTORecordingTypeEnum,
  BatchRecordingTypeEnum,

  ShippingNoteCurrentStateEnum,
  DeliverySheetCurrentUrstammStateEnum,
  DeliverySheetDTO,
  DeliverySheetDTOCurrentStateEnum,
  DeliverySheetDTOCurrentUrstammStateEnum,
  DeliverySheetDTORecordingTypeEnum,
  DeliverySheetRecordingTypeEnum,
  LoggingCurrentStateEnum,
  OtherAssortmentSpeciesEnum,
  PacketCurrentStateEnum,
  PileCurrentStateEnum,
  PileWoodTypeEnum,
  TreeCurrentStateEnum,
  TreeDTORecordingTypeEnum,
  TreeDTOSpeciesEnum,
  TreeRecordingTypeEnum,
  TrunkCurrentStateEnum,
  TrunkSpeciesEnum,
  UserExtendedTypeEnum,
  PacketSpecies,
  PacketSpeciesSpeciesEnum,
  ProcessorBatchCurrentStateEnum,
  ProcessorBatchDTOCurrentStateEnum,
  ProcessorShippingNoteCurrentStateEnum,
  ProcessorShippingNoteDTOCurrentStateEnum,
  ProductSpeciesSpeciesEnum,
  ProductSpecies,
  ProductCurrentStateEnum,
  ProcessorBatchDTORecordingTypeEnum,
  ProcessorBatchRecordingTypeEnum,
  ProjectCurrentStateEnum
} from '@services/apis/generated';
import { ShippingNoteDTO, ShippingNoteDTOCurrentStateEnum } from '@services/apis/generated/models/ShippingNoteDTO';
import React from 'react';
import { StyleProp, TextStyle } from 'react-native';
import { ColorTheme } from '../styles/ColorTheme';

export const getColorByLoggingState = (data: LoggingCurrentStateEnum): StyleProp<TextStyle> => {
  switch (data) {
    case LoggingCurrentStateEnum.InProgress:
      return { color: ColorTheme.yellow };
    case LoggingCurrentStateEnum.Closed:
      return { color: ColorTheme.mainGreen };
  }
};

export const getIconByLoggingState = (data: LoggingCurrentStateEnum): JSX.Element => {
  switch (data) {
    case LoggingCurrentStateEnum.InProgress:
      return <UrstammImageInProgress width={20} height={20} />;
    case LoggingCurrentStateEnum.Closed:
      return <UrstammImageDone width={20} height={20} />;
  }
};

export const getTextByLoggingState = (data: LoggingCurrentStateEnum): string => {
  switch (data) {
    case LoggingCurrentStateEnum.InProgress:
      return i18n.t('utility_components.list.in_progress');
    case LoggingCurrentStateEnum.Closed:
      return i18n.t('utility_components.list.closed');
    default:
      return data;
  }
};

export const getTextByTreeOrTrunkSpecies = (
  data: TreeDTOSpeciesEnum | TrunkSpeciesEnum | PacketSpeciesSpeciesEnum | ProductSpeciesSpeciesEnum
): any => {
  switch (data) {
    case TreeDTOSpeciesEnum.Acer:
      return i18n.t('generics.tree_species_enum.acer');
    case TreeDTOSpeciesEnum.Alder:
      return i18n.t('generics.tree_species_enum.alder');
    case TreeDTOSpeciesEnum.Apple:
      return i18n.t('generics.tree_species_enum.apple');
    case TreeDTOSpeciesEnum.Arolla:
      return i18n.t('generics.tree_species_enum.arolla');
    case TreeDTOSpeciesEnum.Ash:
      return i18n.t('generics.tree_species_enum.ash');
    case TreeDTOSpeciesEnum.Beech:
      return i18n.t('generics.tree_species_enum.beech');
    case TreeDTOSpeciesEnum.Birch:
      return i18n.t('generics.tree_species_enum.birch');
    case TreeDTOSpeciesEnum.BlackPine:
      return i18n.t('generics.tree_species_enum.blackPine');
    case TreeDTOSpeciesEnum.CherryTree:
      return i18n.t('generics.tree_species_enum.cherryTree');
    case TreeDTOSpeciesEnum.Chestnut:
      return i18n.t('generics.tree_species_enum.chestnut');
    case TreeDTOSpeciesEnum.DouglasFire:
      return i18n.t('generics.tree_species_enum.douglasFire');
    case TreeDTOSpeciesEnum.Elm:
      return i18n.t('generics.tree_species_enum.elm');
    case TreeDTOSpeciesEnum.FieldMaple:
      return i18n.t('generics.tree_species_enum.fieldMaple');
    case TreeDTOSpeciesEnum.Fir:
      return i18n.t('generics.tree_species_enum.fir');
    case TreeDTOSpeciesEnum.Hornbeam:
      return i18n.t('generics.tree_species_enum.hornbeam');
    case TreeDTOSpeciesEnum.Larch:
      return i18n.t('generics.tree_species_enum.larch');
    case TreeDTOSpeciesEnum.Linden:
      return i18n.t('generics.tree_species_enum.linden');
    case TreeDTOSpeciesEnum.MixedHardwood:
      return i18n.t('generics.tree_species_enum.mixedHardwood');
    case TreeDTOSpeciesEnum.MixedSoftwood:
      return i18n.t('generics.tree_species_enum.mixedSoftwood');
    case TreeDTOSpeciesEnum.NorwayMaple:
      return i18n.t('generics.tree_species_enum.norwayMaple');
    case TreeDTOSpeciesEnum.Oak:
      return i18n.t('generics.tree_species_enum.oak');
    case TreeDTOSpeciesEnum.OtherHardWood:
      return i18n.t('generics.tree_species_enum.otherHardWood');
    case TreeDTOSpeciesEnum.OtherSoftWood:
      return i18n.t('generics.tree_species_enum.otherSoftWood');
    case TreeDTOSpeciesEnum.Pear:
      return i18n.t('generics.tree_species_enum.pear');
    case TreeDTOSpeciesEnum.Pine:
      return i18n.t('generics.tree_species_enum.pine');
    case TreeDTOSpeciesEnum.RedOak:
      return i18n.t('generics.tree_species_enum.redOak');
    case TreeDTOSpeciesEnum.Spruce:
      return i18n.t('generics.tree_species_enum.spruce');
    case TreeDTOSpeciesEnum.Sycamore:
      return i18n.t('generics.tree_species_enum.sycamore');
    case TreeDTOSpeciesEnum.Walnut:
      return i18n.t('generics.tree_species_enum.walnut');
    case TreeDTOSpeciesEnum.WhitePine:
      return i18n.t('generics.tree_species_enum.whitePine');
    default:
      return '--';
  }
};

export const getTextByPacketSpecies = (
  data: PacketSpecies[] | PacketSpecies | Set<PacketSpecies>
): any => {
  if (data instanceof Set) {
    return getTextByPacketSpecies([...data.values()]);
  }
  if (Array.isArray(data)) {
    const values = data.map(d => getTextByPacketSpecies(d));
    return values.join(', ');
  }

  switch (data.species) {
    case PacketSpeciesSpeciesEnum.Acer:
      return i18n.t('generics.tree_species_enum.acer');
    case PacketSpeciesSpeciesEnum.Alder:
      return i18n.t('generics.tree_species_enum.alder');
    case PacketSpeciesSpeciesEnum.Apple:
      return i18n.t('generics.tree_species_enum.apple');
    case PacketSpeciesSpeciesEnum.Arolla:
      return i18n.t('generics.tree_species_enum.arolla');
    case PacketSpeciesSpeciesEnum.Ash:
      return i18n.t('generics.tree_species_enum.ash');
    case PacketSpeciesSpeciesEnum.Beech:
      return i18n.t('generics.tree_species_enum.beech');
    case PacketSpeciesSpeciesEnum.Birch:
      return i18n.t('generics.tree_species_enum.birch');
    case PacketSpeciesSpeciesEnum.BlackPine:
      return i18n.t('generics.tree_species_enum.blackPine');
    case PacketSpeciesSpeciesEnum.CherryTree:
      return i18n.t('generics.tree_species_enum.cherryTree');
    case PacketSpeciesSpeciesEnum.Chestnut:
      return i18n.t('generics.tree_species_enum.chestnut');
    case PacketSpeciesSpeciesEnum.DouglasFire:
      return i18n.t('generics.tree_species_enum.douglasFire');
    case PacketSpeciesSpeciesEnum.Elm:
      return i18n.t('generics.tree_species_enum.elm');
    case PacketSpeciesSpeciesEnum.FieldMaple:
      return i18n.t('generics.tree_species_enum.fieldMaple');
    case PacketSpeciesSpeciesEnum.Fir:
      return i18n.t('generics.tree_species_enum.fir');
    case PacketSpeciesSpeciesEnum.Hornbeam:
      return i18n.t('generics.tree_species_enum.hornbeam');
    case PacketSpeciesSpeciesEnum.Larch:
      return i18n.t('generics.tree_species_enum.larch');
    case PacketSpeciesSpeciesEnum.Linden:
      return i18n.t('generics.tree_species_enum.linden');
    case PacketSpeciesSpeciesEnum.MixedHardwood:
      return i18n.t('generics.tree_species_enum.mixedHardwood');
    case PacketSpeciesSpeciesEnum.MixedSoftwood:
      return i18n.t('generics.tree_species_enum.mixedSoftwood');
    case PacketSpeciesSpeciesEnum.NorwayMaple:
      return i18n.t('generics.tree_species_enum.norwayMaple');
    case PacketSpeciesSpeciesEnum.Oak:
      return i18n.t('generics.tree_species_enum.oak');
    case PacketSpeciesSpeciesEnum.OtherHardWood:
      return i18n.t('generics.tree_species_enum.otherHardWood');
    case PacketSpeciesSpeciesEnum.OtherSoftWood:
      return i18n.t('generics.tree_species_enum.otherSoftWood');
    case PacketSpeciesSpeciesEnum.Pear:
      return i18n.t('generics.tree_species_enum.pear');
    case PacketSpeciesSpeciesEnum.Pine:
      return i18n.t('generics.tree_species_enum.pine');
    case PacketSpeciesSpeciesEnum.RedOak:
      return i18n.t('generics.tree_species_enum.redOak');
    case PacketSpeciesSpeciesEnum.Spruce:
      return i18n.t('generics.tree_species_enum.spruce');
    case PacketSpeciesSpeciesEnum.Sycamore:
      return i18n.t('generics.tree_species_enum.sycamore');
    case PacketSpeciesSpeciesEnum.Walnut:
      return i18n.t('generics.tree_species_enum.walnut');
    case PacketSpeciesSpeciesEnum.WhitePine:
      return i18n.t('generics.tree_species_enum.whitePine');
    default:
      return '--';
  }
};

export const getTextByProductSpecies = (
  data: ProductSpecies[] | ProductSpecies | Set<ProductSpecies>
): any => {
  if (data instanceof Set) {
    return getTextByProductSpecies([...data.values()]);
  }
  if (Array.isArray(data)) {
    const values = data.map(d => getTextByProductSpecies(d));
    return values.join(', ');
  }

  if(!data || !data.species) return '';

  switch (data.species) {
    case ProductSpeciesSpeciesEnum.Acer:
      return i18n.t('generics.tree_species_enum.acer');
    case ProductSpeciesSpeciesEnum.Alder:
      return i18n.t('generics.tree_species_enum.alder');
    case ProductSpeciesSpeciesEnum.Apple:
      return i18n.t('generics.tree_species_enum.apple');
    case ProductSpeciesSpeciesEnum.Arolla:
      return i18n.t('generics.tree_species_enum.arolla');
    case ProductSpeciesSpeciesEnum.Ash:
      return i18n.t('generics.tree_species_enum.ash');
    case ProductSpeciesSpeciesEnum.Beech:
      return i18n.t('generics.tree_species_enum.beech');
    case ProductSpeciesSpeciesEnum.Birch:
      return i18n.t('generics.tree_species_enum.birch');
    case ProductSpeciesSpeciesEnum.BlackPine:
      return i18n.t('generics.tree_species_enum.blackPine');
    case ProductSpeciesSpeciesEnum.CherryTree:
      return i18n.t('generics.tree_species_enum.cherryTree');
    case ProductSpeciesSpeciesEnum.Chestnut:
      return i18n.t('generics.tree_species_enum.chestnut');
    case ProductSpeciesSpeciesEnum.DouglasFire:
      return i18n.t('generics.tree_species_enum.douglasFire');
    case ProductSpeciesSpeciesEnum.Elm:
      return i18n.t('generics.tree_species_enum.elm');
    case ProductSpeciesSpeciesEnum.FieldMaple:
      return i18n.t('generics.tree_species_enum.fieldMaple');
    case ProductSpeciesSpeciesEnum.Fir:
      return i18n.t('generics.tree_species_enum.fir');
    case ProductSpeciesSpeciesEnum.Hornbeam:
      return i18n.t('generics.tree_species_enum.hornbeam');
    case ProductSpeciesSpeciesEnum.Larch:
      return i18n.t('generics.tree_species_enum.larch');
    case ProductSpeciesSpeciesEnum.Linden:
      return i18n.t('generics.tree_species_enum.linden');
    case ProductSpeciesSpeciesEnum.MixedHardwood:
      return i18n.t('generics.tree_species_enum.mixedHardwood');
    case ProductSpeciesSpeciesEnum.MixedSoftwood:
      return i18n.t('generics.tree_species_enum.mixedSoftwood');
    case ProductSpeciesSpeciesEnum.NorwayMaple:
      return i18n.t('generics.tree_species_enum.norwayMaple');
    case ProductSpeciesSpeciesEnum.Oak:
      return i18n.t('generics.tree_species_enum.oak');
    case ProductSpeciesSpeciesEnum.OtherHardWood:
      return i18n.t('generics.tree_species_enum.otherHardWood');
    case ProductSpeciesSpeciesEnum.OtherSoftWood:
      return i18n.t('generics.tree_species_enum.otherSoftWood');
    case ProductSpeciesSpeciesEnum.Pear:
      return i18n.t('generics.tree_species_enum.pear');
    case ProductSpeciesSpeciesEnum.Pine:
      return i18n.t('generics.tree_species_enum.pine');
    case ProductSpeciesSpeciesEnum.RedOak:
      return i18n.t('generics.tree_species_enum.redOak');
    case ProductSpeciesSpeciesEnum.Spruce:
      return i18n.t('generics.tree_species_enum.spruce');
    case ProductSpeciesSpeciesEnum.Sycamore:
      return i18n.t('generics.tree_species_enum.sycamore');
    case ProductSpeciesSpeciesEnum.Walnut:
      return i18n.t('generics.tree_species_enum.walnut');
    case ProductSpeciesSpeciesEnum.WhitePine:
      return i18n.t('generics.tree_species_enum.whitePine');
    default:
      return '--';
  }
};

export const getTextByWoodType = (data?: PileWoodTypeEnum): any => {
  switch (data) {
    case PileWoodTypeEnum.OtherAssortment:
      return i18n.t('generics.other_assortment');
    case PileWoodTypeEnum.Timber:
      return i18n.t('generics.timber');
  }
};

export const getTextByTreeType = (data?: TreeDTORecordingTypeEnum | TreeRecordingTypeEnum): any => {
  switch (data) {
    case TreeDTORecordingTypeEnum.Single:
      return i18n.t('generics.single');
    case TreeDTORecordingTypeEnum.Multiple:
      return i18n.t('generics.multiple');
    case TreeDTORecordingTypeEnum.Date:
      return i18n.t('generics.date');
    default:
      return '--';
  }
};

export const getTextByDsRecordingType = (
  data?: DeliverySheetDTORecordingTypeEnum | DeliverySheetRecordingTypeEnum
): any => {
  switch (data) {
    case DeliverySheetDTORecordingTypeEnum.Single:
      return i18n.t('generics.single');
    case DeliverySheetDTORecordingTypeEnum.Multiple:
      return i18n.t('generics.multiple');
    case DeliverySheetDTORecordingTypeEnum.Date:
      return i18n.t('generics.date');
    default:
      return '--';
  }
};

export const getTextByBatchType = (data?: BatchRecordingTypeEnum | BatchDTORecordingTypeEnum): any => {
  switch (data) {
    case BatchRecordingTypeEnum.Single:
      return i18n.t('generics.single');
    case BatchRecordingTypeEnum.Multiple:
      return i18n.t('generics.multiple');
    case BatchRecordingTypeEnum.Date:
      return i18n.t('generics.date');
    default:
      return '--';
  }
};

export const getTextByProcessorBatchType = (data?: ProcessorBatchRecordingTypeEnum | ProcessorBatchDTORecordingTypeEnum): any => {
  switch (data) {
    case ProcessorBatchRecordingTypeEnum.Single:
      return i18n.t('generics.single');
    case ProcessorBatchRecordingTypeEnum.Multiple:
      return i18n.t('generics.multiple');
    case ProcessorBatchRecordingTypeEnum.Date:
      return i18n.t('generics.date');
    default:
      return '--';
  }
};

export const getTextByOtherAssortmentSpecies = (data: OtherAssortmentSpeciesEnum): string => {
  switch (data) {
    case OtherAssortmentSpeciesEnum.Acer:
      return i18n.t('generics.tree_species_enum.acer');
    case OtherAssortmentSpeciesEnum.Alder:
      return i18n.t('generics.tree_species_enum.alder');
    case OtherAssortmentSpeciesEnum.Apple:
      return i18n.t('generics.tree_species_enum.apple');
    case OtherAssortmentSpeciesEnum.Arolla:
      return i18n.t('generics.tree_species_enum.arolla');
    case OtherAssortmentSpeciesEnum.Ash:
      return i18n.t('generics.tree_species_enum.ash');
    case OtherAssortmentSpeciesEnum.Beech:
      return i18n.t('generics.tree_species_enum.beech');
    case OtherAssortmentSpeciesEnum.Birch:
      return i18n.t('generics.tree_species_enum.birch');
    case OtherAssortmentSpeciesEnum.BlackPine:
      return i18n.t('generics.tree_species_enum.blackPine');
    case OtherAssortmentSpeciesEnum.CherryTree:
      return i18n.t('generics.tree_species_enum.cherryTree');
    case OtherAssortmentSpeciesEnum.Chestnut:
      return i18n.t('generics.tree_species_enum.chestnut');
    case OtherAssortmentSpeciesEnum.DouglasFire:
      return i18n.t('generics.tree_species_enum.douglasFire');
    case OtherAssortmentSpeciesEnum.Elm:
      return i18n.t('generics.tree_species_enum.elm');
    case OtherAssortmentSpeciesEnum.FieldMaple:
      return i18n.t('generics.tree_species_enum.fieldMaple');
    case OtherAssortmentSpeciesEnum.Fir:
      return i18n.t('generics.tree_species_enum.fir');
    case OtherAssortmentSpeciesEnum.Hornbeam:
      return i18n.t('generics.tree_species_enum.hornbeam');
    case OtherAssortmentSpeciesEnum.Larch:
      return i18n.t('generics.tree_species_enum.larch');
    case OtherAssortmentSpeciesEnum.Linden:
      return i18n.t('generics.tree_species_enum.linden');
    case OtherAssortmentSpeciesEnum.MixedHardwood:
      return i18n.t('generics.tree_species_enum.mixedHardwood');
    case OtherAssortmentSpeciesEnum.MixedSoftwood:
      return i18n.t('generics.tree_species_enum.mixedSoftwood');
    case OtherAssortmentSpeciesEnum.NorwayMaple:
      return i18n.t('generics.tree_species_enum.norwayMaple');
    case OtherAssortmentSpeciesEnum.Oak:
      return i18n.t('generics.tree_species_enum.oak');
    case OtherAssortmentSpeciesEnum.OtherHardWood:
      return i18n.t('generics.tree_species_enum.otherHardWood');
    case OtherAssortmentSpeciesEnum.OtherSoftWood:
      return i18n.t('generics.tree_species_enum.otherSoftWood');
    case OtherAssortmentSpeciesEnum.Pear:
      return i18n.t('generics.tree_species_enum.pear');
    case OtherAssortmentSpeciesEnum.Pine:
      return i18n.t('generics.tree_species_enum.pine');
    case OtherAssortmentSpeciesEnum.RedOak:
      return i18n.t('generics.tree_species_enum.redOak');
    case OtherAssortmentSpeciesEnum.Spruce:
      return i18n.t('generics.tree_species_enum.spruce');
    case OtherAssortmentSpeciesEnum.Sycamore:
      return i18n.t('generics.tree_species_enum.sycamore');
    case OtherAssortmentSpeciesEnum.Walnut:
      return i18n.t('generics.tree_species_enum.walnut');
    case OtherAssortmentSpeciesEnum.WhitePine:
      return i18n.t('generics.tree_species_enum.whitePine');
  }
};

export const loggingStateIsClosed = (data: LoggingCurrentStateEnum): boolean => {
  switch (data) {
    case LoggingCurrentStateEnum.InProgress:
      return false;
    case LoggingCurrentStateEnum.Closed:
      return true;
    default:
      return false;
  }
};

export const getColorByPileState = (data: PileCurrentStateEnum): StyleProp<TextStyle> => {
  switch (data) {
    case PileCurrentStateEnum.InProgress:
      return { color: ColorTheme.yellow };
    case PileCurrentStateEnum.Closed:
      return { color: ColorTheme.mainGreen };
  }
};

export const getIconByPileState = (data: PileCurrentStateEnum): JSX.Element => {
  switch (data) {
    case PileCurrentStateEnum.InProgress:
      return <UrstammImageInProgress width={20} height={20} />;
    case PileCurrentStateEnum.Closed:
      return <UrstammImageDone width={20} height={20} />;
  }
};

export const getTextByPileState = (data: PileCurrentStateEnum): string => {
  switch (data) {
    case PileCurrentStateEnum.InProgress:
      return i18n.t('utility_components.list.in_progress');
    case PileCurrentStateEnum.Closed:
      return i18n.t('utility_components.list.done');
  }
};

export const pileStateIsClosed = (data: PileCurrentStateEnum): boolean => {
  switch (data) {
    case PileCurrentStateEnum.InProgress:
      return false;
    case PileCurrentStateEnum.Closed:
      return true;
  }
};

//TODO: ICONS
export const getIconByTreeState = (data: TreeCurrentStateEnum): JSX.Element => {
  switch (data) {
    case TreeCurrentStateEnum.Cut:
      return <UrstammImageInProgress width={20} height={20} />;
    case TreeCurrentStateEnum.MovedToSawmill:
      return <UrstammImageDone width={20} height={20} />;
    case TreeCurrentStateEnum.Standing:
      return <UrstammImageDone width={20} height={20} />;
  }
};

export const getTextByTreeState = (data: TreeCurrentStateEnum): string => {
  switch (data) {
    case TreeCurrentStateEnum.Cut:
      return i18n.t('utility_components.list.cut');
    case TreeCurrentStateEnum.MovedToSawmill:
      return i18n.t('utility_components.list.moved_to_sawmill');
    case TreeCurrentStateEnum.Standing:
      return i18n.t('utility_components.list.standing');
  }
};

export const getIconByPacketState = (data: PacketCurrentStateEnum): JSX.Element => {
  switch (data) {
    case PacketCurrentStateEnum.InProgress:
      return <UrstammImageInProgress width={20} height={20} />;
    case PacketCurrentStateEnum.SentInShippingNote:
    case PacketCurrentStateEnum.Closed:
    case PacketCurrentStateEnum.SentInShippingNote:
      return <UrstammImageDone width={20} height={20} />;
  }
};

export const getColorByPacketState = (data: PacketCurrentStateEnum): StyleProp<TextStyle> => {
  switch (data) {
    case PacketCurrentStateEnum.InProgress:
      return { color: ColorTheme.mediumGrey };
    case PacketCurrentStateEnum.Closed:
    case PacketCurrentStateEnum.SentInShippingNote:
    case PacketCurrentStateEnum.SelectedForShippingNote:
      return { color: ColorTheme.mainGreen };
  }
};

export const getTextByPacketState = (data: PacketCurrentStateEnum): string => {
  switch (data) {
    case PacketCurrentStateEnum.InProgress:
      return i18n.t('utility_components.list.in_progress');
    case PacketCurrentStateEnum.Closed:
      return i18n.t('utility_components.list.closed');
    case PacketCurrentStateEnum.SentInShippingNote:
      return i18n.t('utility_components.list.sent_in_shipping_note');
    case PacketCurrentStateEnum.SelectedForShippingNote:
      return i18n.t('utility_components.list.selected_for_shipping_note');
    default:
      return data;
  }
};

export const getIconByProductState = (data: ProductCurrentStateEnum): JSX.Element => {
  switch (data) {
    case ProductCurrentStateEnum.InProgress:
      return <UrstammImageInProgress width={20} height={20} />;
    case ProductCurrentStateEnum.SentInShippingNote:
    case ProductCurrentStateEnum.Closed:
      return <UrstammImageDone width={20} height={20} />;
  }
};

export const getColorByProductState = (data: ProductCurrentStateEnum): StyleProp<TextStyle> => {
  switch (data) {
    case ProductCurrentStateEnum.InProgress:
      return { color: ColorTheme.mediumGrey };
    case ProductCurrentStateEnum.Closed:
      return { color: ColorTheme.mainGreen };
  }
};

export const getTextByProductState = (data: ProductCurrentStateEnum): string => {
  switch (data) {
    case ProductCurrentStateEnum.InProgress:
      return i18n.t('utility_components.list.in_progress');
    case ProductCurrentStateEnum.SentInShippingNote:
      return i18n.t('utility_components.list.sent_in_shipping_note');
    case ProductCurrentStateEnum.Closed:
      return i18n.t('utility_components.list.closed');
    default:
      return data;
  }
};


export const getIconByProjectState = (data: ProjectCurrentStateEnum): JSX.Element => {
  switch (data) {
    case ProjectCurrentStateEnum.InProgress:
      return <UrstammImageInProgress width={20} height={20} />;
    case ProjectCurrentStateEnum.Closed:
      return <UrstammImageDone width={20} height={20} />;
  }
};

export const getColorByProjectState = (data: ProjectCurrentStateEnum): StyleProp<TextStyle> => {
  switch (data) {
    case ProjectCurrentStateEnum.InProgress:
      return { color: ColorTheme.mediumGrey };
    case ProjectCurrentStateEnum.Closed:
      return { color: ColorTheme.mainGreen };
  }
};

export const getTextByProjectState = (data: ProjectCurrentStateEnum): string => {
  switch (data) {
    case ProjectCurrentStateEnum.InProgress:
      return i18n.t('utility_components.list.in_progress');
    case ProjectCurrentStateEnum.Closed:
      return i18n.t('utility_components.list.closed');
    default:
      return data;
  }
};

export const getTextByDeliverySheetState = (data: DeliverySheetDTOCurrentStateEnum): string => {
  switch (data) {
    case DeliverySheetDTOCurrentStateEnum.InProgressByForestry:
      return i18n.t('utility_components.list.in_progress_by_forestry');
    case DeliverySheetDTOCurrentStateEnum.InProgressBySawmill:
      return i18n.t('utility_components.list.in_progress_by_sawmill');
    case DeliverySheetDTOCurrentStateEnum.Closed:
      return i18n.t('utility_components.list.closed');
    case DeliverySheetDTOCurrentStateEnum.EditedByForestry:
      return i18n.t('utility_components.list.edited_by_forestry');
    case DeliverySheetDTOCurrentStateEnum.EditedBySawmill:
      return i18n.t('utility_components.list.edited_by_sawmill');
    case DeliverySheetDTOCurrentStateEnum.Valid:
      return i18n.t('utility_components.list.valid');
    default:
      return data;
  }
};

export const getTextByDeliverySheetUrstammState = (data?: DeliverySheetDTOCurrentUrstammStateEnum): string => {
  switch (data) {
    case DeliverySheetDTOCurrentUrstammStateEnum.InProgress:
      return i18n.t('utility_components.list.in_progress');
    case DeliverySheetDTOCurrentUrstammStateEnum.Closed:
      return i18n.t('utility_components.list.closed');
    case DeliverySheetDTOCurrentUrstammStateEnum.Delivered:
      return i18n.t('utility_components.list.delivered');
    case DeliverySheetDTOCurrentUrstammStateEnum.Validated:
      return i18n.t('utility_components.list.validated');
    default:
      return data || '--';
  }
};

export const getColorByDeliverySheetState = (data: DeliverySheetDTOCurrentStateEnum): StyleProp<TextStyle> => {
  switch (data) {
    case DeliverySheetDTOCurrentStateEnum.InProgressByForestry:
      return { color: ColorTheme.mediumGrey };
    case DeliverySheetDTOCurrentStateEnum.InProgressBySawmill:
      return { color: ColorTheme.mediumGrey };
    case DeliverySheetDTOCurrentStateEnum.Closed:
      return { color: ColorTheme.mainGreen };
    case DeliverySheetDTOCurrentStateEnum.EditedByForestry:
      return { color: ColorTheme.yellow };
    case DeliverySheetDTOCurrentStateEnum.EditedBySawmill:
      return { color: ColorTheme.yellow };
    case DeliverySheetDTOCurrentStateEnum.Valid:
      return { color: ColorTheme.lightGreen };
  }
};

export const getColorByDeliverySheetUrstammState = (
  data: DeliverySheetDTOCurrentUrstammStateEnum
): StyleProp<TextStyle> => {
  switch (data) {
    case DeliverySheetDTOCurrentUrstammStateEnum.InProgress:
      return { color: ColorTheme.mediumGrey };
    case DeliverySheetDTOCurrentUrstammStateEnum.Closed:
      return { color: ColorTheme.mainGreen };
    case DeliverySheetDTOCurrentUrstammStateEnum.Delivered:
      return { color: ColorTheme.yellow };
    case DeliverySheetDTOCurrentUrstammStateEnum.Validated:
      return { color: ColorTheme.lightGreen };
  }
};

///



export const getTextByTrunkState = (data: TrunkCurrentStateEnum): string => {
  switch (data) {
    case TrunkCurrentStateEnum.InProgressByForestry:
    case TrunkCurrentStateEnum.InProgressBySawmill:
    case TrunkCurrentStateEnum.EditedByForestry:
      return i18n.t('utility_components.list.in_progress');
    case TrunkCurrentStateEnum.EditedBySawmill:
    case TrunkCurrentStateEnum.Valid:
      return i18n.t('utility_components.list.validated');
    default:
      return data;
  }
};

export const getColorByTrunkState = (data: TrunkCurrentStateEnum): StyleProp<TextStyle> => {
  switch (data) {
    case TrunkCurrentStateEnum.InProgressByForestry:
      return { color: ColorTheme.mediumGrey };
    case TrunkCurrentStateEnum.InProgressBySawmill:
      return { color: ColorTheme.mediumGrey };
    case TrunkCurrentStateEnum.EditedByForestry:
      return { color: ColorTheme.yellow };
    case TrunkCurrentStateEnum.EditedBySawmill:
      return { color: ColorTheme.yellow };
    case TrunkCurrentStateEnum.Valid:
      return { color: ColorTheme.lightGreen };
  }
};

export const getIconByBatchState = (data: BatchCurrentStateEnum): JSX.Element => {
  switch (data) {
    case BatchCurrentStateEnum.InProgress:
      return <UrstammImageInProgress width={20} height={20} />;
    case BatchCurrentStateEnum.Closed:
      return <UrstammImageDone width={20} height={20} />;
  }
};

export const getTextByBatchState = (data: BatchCurrentStateEnum | BatchDTOCurrentStateEnum): string | undefined => {
  switch (data) {
    case BatchCurrentStateEnum.InProgress:
      return i18n.t('utility_components.list.in_progress');
    case BatchCurrentStateEnum.Closed:
      return i18n.t('utility_components.list.closed');
  }
};

export const getColorByBatchState = (data: BatchCurrentStateEnum | BatchDTOCurrentStateEnum): StyleProp<TextStyle> => {
  switch (data) {
    case BatchCurrentStateEnum.InProgress:
      return { color: ColorTheme.yellow };
    case BatchCurrentStateEnum.Closed:
      return { color: ColorTheme.mainGreen };
  }
};

export const getIconByProcessorBatchState = (data: ProcessorBatchCurrentStateEnum): JSX.Element => {
  switch (data) {
    case ProcessorBatchCurrentStateEnum.InProgress:
      return <UrstammImageInProgress width={20} height={20} />;
    case ProcessorBatchCurrentStateEnum.Closed:
      return <UrstammImageDone width={20} height={20} />;
  }
};

export const getTextByProcessorBatchState = (data: ProcessorBatchCurrentStateEnum | ProcessorBatchDTOCurrentStateEnum): string | undefined => {
  switch (data) {
    case ProcessorBatchCurrentStateEnum.InProgress:
      return i18n.t('utility_components.list.in_progress');
    case ProcessorBatchCurrentStateEnum.Closed:
      return i18n.t('utility_components.list.closed');
  }
};

export const getColorByProcessorBatchState = (data: ProcessorBatchCurrentStateEnum | ProcessorBatchDTOCurrentStateEnum): StyleProp<TextStyle> => {
  switch (data) {
    case ProcessorBatchCurrentStateEnum.InProgress:
      return { color: ColorTheme.yellow };
    case ProcessorBatchCurrentStateEnum.Closed:
      return { color: ColorTheme.mainGreen };
  }
};
///

export const getIconByShippingNoteState = (data: ShippingNoteCurrentStateEnum ): JSX.Element => {
  switch (data) {
    case ShippingNoteCurrentStateEnum.InProgress:
      return <UrstammImageInProgress width={20} height={20} />;
    case ShippingNoteCurrentStateEnum.Sent:
      return <UrstammImageDone width={20} height={20} />;
    case ShippingNoteCurrentStateEnum.Accepted:
        return <UrstammImageDone width={20} height={20} />;
    case ShippingNoteCurrentStateEnum.Rejected:
          return <UrstammImageDone width={20} height={20} />;
  }
};

export const getTextByShippingNoteState = (data: ShippingNoteCurrentStateEnum | ShippingNoteDTOCurrentStateEnum): string | undefined => {
  switch (data) {
    case ShippingNoteCurrentStateEnum.InProgress:
      return i18n.t('utility_components.list.in_progress');
    case ShippingNoteCurrentStateEnum.Sent:
      return i18n.t('utility_components.list.sent');
    case ShippingNoteCurrentStateEnum.Accepted:
        return i18n.t('utility_components.list.accepted');
    case ShippingNoteCurrentStateEnum.Rejected:
      return i18n.t('utility_components.list.rejected');
    
  }
};

export const getColorByShippingNoteState = (data: ShippingNoteCurrentStateEnum | ShippingNoteDTOCurrentStateEnum): StyleProp<TextStyle> => {
  switch (data) {
    case ShippingNoteCurrentStateEnum.InProgress:
      return { color: ColorTheme.yellow };
    case ShippingNoteCurrentStateEnum.Sent:
      return { color: ColorTheme.mainGreen };
    case ShippingNoteCurrentStateEnum.Accepted:
      return { color: ColorTheme.mainGreen };
    case ShippingNoteCurrentStateEnum.Rejected:
      return { color: ColorTheme.yellow };
  }
};

export const getIconByProcessorShippingNoteState = (data: ProcessorShippingNoteCurrentStateEnum ): JSX.Element => {
  switch (data) {
    case ProcessorShippingNoteCurrentStateEnum.InProgress:
      return <UrstammImageInProgress width={20} height={20} />;
    case ProcessorShippingNoteCurrentStateEnum.Sent:
      return <UrstammImageDone width={20} height={20} />;
    case ProcessorShippingNoteCurrentStateEnum.Accepted:
        return <UrstammImageDone width={20} height={20} />;
    case ProcessorShippingNoteCurrentStateEnum.Rejected:
          return <UrstammImageDone width={20} height={20} />;
  }
  return <></>;
};

export const getTextByProcessorShippingNoteState = (data: ProcessorShippingNoteCurrentStateEnum | ProcessorShippingNoteDTOCurrentStateEnum, asIncoming: boolean = false): string => {
  switch (data) {
    case ProcessorShippingNoteCurrentStateEnum.InProgress:
      return i18n.t('utility_components.list.in_progress');
    case ProcessorShippingNoteCurrentStateEnum.Sent:
      return asIncoming
        ? i18n.t('utility_components.list.received')
        : i18n.t('utility_components.list.sent');
    case ProcessorShippingNoteCurrentStateEnum.Accepted:
      return i18n.t('utility_components.list.accepted');
    case ProcessorShippingNoteCurrentStateEnum.Rejected:
      return i18n.t('utility_components.list.rejected');
  }
};

export const getColorByProcessorShippingNoteState = (data: ProcessorShippingNoteCurrentStateEnum | ProcessorShippingNoteDTOCurrentStateEnum): StyleProp<TextStyle> => {
  switch (data) {
    case ProcessorShippingNoteCurrentStateEnum.InProgress:
      return { color: ColorTheme.yellow };
    case ProcessorShippingNoteCurrentStateEnum.Sent:
      return { color: ColorTheme.mainGreen };
    case ProcessorShippingNoteCurrentStateEnum.Accepted:
      return { color: ColorTheme.mainGreen };
    case ProcessorShippingNoteCurrentStateEnum.Rejected:
      return { color: ColorTheme.yellow };
  }
};

///
export const getDSTextBySentToSawmillValue = (data: DeliverySheetDTO): string => {
  if (data.sentToSawmill) return i18n.t('generics.delivered');

  return i18n.t('generics.waiting_for_validation');
};

export const getDSColorBySentToSawmillValue = (data: DeliverySheetDTO): StyleProp<TextStyle> => {
  if (data.sentToSawmill) return { color: ColorTheme.lightGreen };

  return { color: ColorTheme.yellow };
};

export const getDSStatusBySentToSawmillValue = (data: DeliverySheetDTO, userType: UserExtendedTypeEnum): boolean => {
  switch (data.currentUrstammState) {
    case DeliverySheetDTOCurrentUrstammStateEnum.InProgress:
      return data.sentToSawmill ?? false;
    case DeliverySheetDTOCurrentUrstammStateEnum.Delivered:
      return (data.sentToSawmill && userType == UserExtendedTypeEnum.Forestry) ?? false;
    case DeliverySheetDTOCurrentUrstammStateEnum.Validated:
      return (data.sentToSawmill && userType == UserExtendedTypeEnum.Sawmill) ?? false;
    case DeliverySheetDTOCurrentUrstammStateEnum.Closed:
      return true;
  }

  return false;
};

export const getDSStatusBySentToSawmillValueForSwitch = (data: DeliverySheetDTO): boolean => {
  if (data.sentToSawmill) return true;

  return false;
};

export const getDSDisabledByTrunkListAndSawmill = (data: DeliverySheetDTO): boolean => {
  if (!data.sawmill || data.sentToSawmill) return true;

  return false;
};


