import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImageNewTree from '@components/images/corner/UrstammImageNewTree';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import {
  applySavingToReference,
  deepCopyAndAddSelectedParameter,
  restoreListToReference,
  selectElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ProcessorShippingNoteRegistrationView from '@components/views/processor-shipping-note/ProcessorShippingNoteRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { resetOutgoingProcessorShippingNoteList } from '@redux/features/processor-shipping-note/outgoingProcessorShippingNoteSlice';
import { setDeliverySheetPage } from '@redux/features/delivery-sheet/deliverySheetSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { RootState } from '@redux/store';

import {
  customProcessorBatchResourceApi,
  customCompanyResourceApi,
  customProductResourceApi,
  customProcessorShippingNoteResourceApi
} from '@services/apis/ApiConfiguration';

import {
  ProcessorShippingNote,
  Product,
  ProductCurrentStateEnum,
  ProcessorShippingNoteCurrentStateEnum,
  ProcessorBatch,
  ProcessorBatchCurrentStateEnum,
  ProcessorBatchDTO,
  ProcessorBatchRecordingTypeEnum,
  ProcessorBatchDTOCurrentStateEnum,
  ProcessorBatchResourceApi,
  CustomProcessorBatchResourceApi,
  CustomProductResourceApi,
  PageProcessorBatch,
  Company,
  PageCompany
} from '@services/apis/generated';

import React, { useCallback, useState } from 'react';
import { Keyboard } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { formatPhotoNumber, generateSet, isCompanyMock, sleep } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';
import { ProcessorShippingNoteDTO } from '@services/apis/generated/models/ProcessorShippingNoteDTO';
import { setProcessorBatchList, setProcessorBatchListSort, setProcessorBatchPage, setProcessorBatchTotalPages } from '@redux/features/processor-batch/processorBatchSlice';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createOnlyIdProcessorBatch, createOnlyIdCompany } from '../../utils/classes/UrstammClassMapper';
import { setProductPage } from '@redux/features/product/productSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function ProcessorShippingNoteRegistrationContainer({
  navigation,
  route
}: RootStackScreenProps<'ProcessorShippingNoteRegistration'>) {
  const rdxProcessorBatchList = useSelector((state: RootState) => state.persistedReducer.processorBatch.list);
  const rdxProcessorBatchCompleteList = useSelector((state: RootState) => state.persistedReducer.processorBatch.completeList);
  const rdxProcessorBatchPage = useSelector((state: RootState) => state.persistedReducer.processorBatch.page);

  const rdxProductList = useSelector((state: RootState) => state.persistedReducer.product.list);
  const rdxProductCompleteList = useSelector((state: RootState) => state.persistedReducer.product.completeList);
  const rdxProductPage = useSelector((state: RootState) => state.persistedReducer.product.page);

  const rdxProcessorBatchTotalPages = useSelector((state: RootState) => state.persistedReducer.processorBatch.totalPages);
  const rdxProductTotalPages = useSelector((state: RootState) => state.persistedReducer.product.totalPages);
  const rdxProcessorBatchSortBy = useSelector((state: RootState) => state.persistedReducer.processorBatch.sortBy);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const rdxMyCompany = useSelector((state: RootState) => state.persistedReducer.user.myCompany);
  const dispatch = useDispatch();

  const [processorShippingNoteList, setProcessorShippingNoteList] = useStateWithCallbackLazy<ProcessorShippingNote[]>([]);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [processorBatchListState, setProcessorBatchListState] = useState<any>();
  const [processorBatchSelected, setProcessorBatchSelected] = useState<any>();
  const [processorSelected, setProcessorSelected] = useState<Company>();
  const [productList, setProductList] = useState<any>();
  const [processorList, setProcessorList] = useState<Company[]>([]);
  const [processorBatchListReference, setProcessorBatchListReference] = useState<any>();

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: undefined,
      icon: (
        <UrstammImageNewTree
          width={PlatformHelper.normalizeByDevice(34)}
          height={PlatformHelper.normalizeByDevice(24)}
        />
      )
    }
  };

  React.useEffect(() => {
    customProcessorBatchResourceApi
      .getPageProcessorBatchesForMyCompany({
        customProcessorBatchCriteria: { currentState: {} },
        page: rdxProcessorBatchPage,
        size: 200
      })
      .then((processorBatchList: PageProcessorBatch) => {
        dispatch(changeLoaderStatus(false));
        if (processorBatchList) {
          dispatch(setProcessorBatchList(processorBatchList.content));
          let list: ProcessorBatchDTO[] = JSON.parse(JSON.stringify(processorBatchList.content));

          list.sort((a, b) => {
            if (a?.creationDate && b?.creationDate) {
              return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
            }
            return a?.creationDate ? -1 : 1;
          });

          setProcessorShippingNoteList(route.params?.processorShippingNoteList, updatedProcessorShippingNoteList => {
            deepCopyAndAddSelectedParameter(list, setProcessorBatchListState, setProcessorBatchListReference);
          });
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  }, []);

  /**
   * Filtra la lita di prodotti inseribili all'interno del processo batch
   * */
  React.useEffect(() => {
    if (processorBatchSelected) {
      customProductResourceApi
        .getProductsByBatch({
          batchId: processorBatchSelected.id
        })
        .then((productList: Product[]) => {
          productList = productList.filter(
            item =>
              item.currentState == ProductCurrentStateEnum.Closed && 
              !item.project &&
              item.sumCubage &&
              (item.processor?.id == processorSelected?.id ||
                (isCompanyMock(item.processor) && isCompanyMock(processorSelected)))
          );
          setProductList(productList);
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    }
  }, [processorBatchSelected, processorSelected]);

  const processorBatchSelectID = processorBatch => {
    setProcessorBatchSelected(processorBatch);
  };

  const processorSelectID = processor => {
    setProcessorSelected(processor);
  };

  const getAllCompanyProcessorConnectedToMe = () => {
    //let sortBy = order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['name,desc'];
    dispatch(changeLoaderStatus(true));

    customCompanyResourceApi
      .getCompaniesConnectedToMe({
        //sort: sortBy,
        customCompanyCriteria: {},
        page: 0,
        size: 200
      })
      .then((list: PageCompany) => {
        dispatch(changeLoaderStatus(false));

        if (list && list.content?.length) {
          list.content.push({ name: i18n.t('views.processor_shipping_note.no_urstamm_processor'), id: -200 });
          setProcessorList(list.content);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  useFocusEffect(
    useCallback(() => {
      setResetForm(false);

      if (route.params?.processorShippingNoteList) {
        //TODO: Test
      }
    }, [route.params])
  );

  const saveProcessorShippingNote = (processorShippingNote: ProcessorShippingNoteDTO) => {
    let productsSet: Set<Product> = productList ? new Set(generateSet(productList)) : new Set();

    dispatch(changeLoaderStatus(true));

    let processorShippingNoteObj: ProcessorShippingNote = {
      name: processorShippingNote.name!,
      creationDate: processorShippingNote.creationDate,
      currentState: ProcessorShippingNoteCurrentStateEnum.InProgress,
      comment: processorShippingNote.comment!,
      outgoingBatch: createOnlyIdProcessorBatch(processorShippingNote.outgoingBatch),
      processor: createOnlyIdCompany(processorShippingNote.processor),
      incomingProducts: productsSet
    };

    customProcessorShippingNoteResourceApi
      .createProcessorShippingNoteCustom({ processorShippingNote: processorShippingNoteObj })
      .then((processorShippingNote: ProcessorShippingNote) => {
        dispatch(changeLoaderStatus(false));
        if (processorShippingNote) {
          setResetForm(true);
          dispatch(resetOutgoingProcessorShippingNoteList());
          UrstammNavigationHelper.navigateToOutgoingProcessorShippingNoteList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const goBack = async (): Promise<void> => {
    dispatch(resetOutgoingProcessorShippingNoteList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToOutgoingProcessorShippingNoteList(navigation, true);
  };

  const getMoreProcessorBatchItems = (): void => {
    if (rdxProcessorBatchPage < rdxProcessorBatchTotalPages - 1) {
      dispatch(setProcessorBatchPage(rdxProcessorBatchPage + 1));
    }
  };

  const getMoreProductItems = (): void => {
    if (rdxProductPage < rdxProductTotalPages - 1) {
      dispatch(setProductPage(rdxProductPage + 1));
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'shipping_note_title'}
          text={i18n.t('views.processor_shipping_note.processor_shipping_note_registration.new_shipping_note')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <ProcessorShippingNoteRegistrationView
          navigation={navigation}
          submitProcessorShippingNoteForm={(data: ProcessorShippingNoteDTO) => saveProcessorShippingNote(data)}
          resetProcessorShippingNoteForm={resetForm}
          processorBatchProps={{
            processorBatchList: processorBatchListState,
            processorBatchSelected: processorBatchSelected => selectElementFormList(processorBatchSelected, setProcessorBatchListState, processorBatchListState),
            moreItems: getMoreProcessorBatchItems,
            resetProcessorBatchSelected: () => restoreListToReference(setProcessorBatchListState, processorBatchListReference),
            processorBatchSelectedID: processorBatch => processorBatchSelectID(processorBatch)
          }}
          processorProps={{
            getAll: getAllCompanyProcessorConnectedToMe,
            processorList: processorList,
            processorSelectedID: processor => processorSelectID(processor)
          }}
          productProps={{
            productList: productList,
            productSelected: productSelected => selectElementFormList(productSelected, setProductList, productList),
            moreItems: getMoreProductItems
          }}
          userExtendedMe={rdxUserExtendedMe}
        />
      }
    />
  );
}
