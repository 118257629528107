import UrstammImageLogo from '@components/images/UrstammImageLogo';
import UrstammButtonCorner from '@components/utility-components/button/UrstammButtonCorner';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { DrawerActions } from '@react-navigation/native';
import { RootState } from '@redux/store';
import React from 'react';
import { Platform, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { useSelector } from 'react-redux';
import { truncate } from '../../../utils/classes/UrstammUtilityFunctions';
import { UrstammFontsize } from '../../../utils/styles/UrstammFont';
import { UrstammStyle, UrstammStyleCornerButton, UrstammStyleLayout } from '../../../utils/styles/UrstammStyle';

interface CornerOptions {
  text?: string;
  subText?: string;
  icon: JSX.Element | undefined;
  showCorner?: boolean;
}

export interface BaseLayoutOptions {
  cornerTopLeft: CornerOptions;
  cornerTopRight: CornerOptions;
  cornerBottomLeft: CornerOptions;
  cornerBottomRight: CornerOptions;
}

export interface ContainerStyle {
  topContainer: StyleProp<ViewStyle>;
  bottomContainer: StyleProp<ViewStyle>;
  bottomSubContainer: StyleProp<ViewStyle>;
  headerContainer: StyleProp<ViewStyle>;
  headerDetails?: StyleProp<ViewStyle>;
  cornerBottomLeft: StyleProp<ViewStyle>;
  cornerBottomRight: StyleProp<ViewStyle>;
}

export interface HeaderDetailsOptions {
  title: string;
  value: string;
  truncateValue?: number;
}

export default function BaseLayoutCorner(props: {
  navigation: any;
  cornerOptions: BaseLayoutOptions;
  layoutStyle: ContainerStyle;
  view: JSX.Element;
  titleSubViewStyle?: StyleProp<ViewStyle>;
  title?: JSX.Element;
  subTitle?: JSX.Element;
  bottomTitle?: JSX.Element;
  headerDetails?: HeaderDetailsOptions[];
  showSearchInput?: boolean;
  submitTopLeft?: () => void;
  submitTopRight?: () => void;
  submitBottomLeft?: () => void;
  submitBottomRight?: () => void;
}) {
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const inputIsFocused = useSelector((state: RootState) => state.persistedReducer.input.isFocused);

  const openDrawer = () => {
    props.navigation.dispatch(DrawerActions.toggleDrawer());
  };

  return (
    <View style={UrstammStyleLayout.mainContainer}>
      <View style={styles.appBar} />
      {/**Top layout */}
      <View style={[props.layoutStyle.topContainer, UrstammStyle.zIndex2]}>
        {props.cornerOptions.cornerTopLeft.showCorner && (
          <View style={[UrstammStyleCornerButton.cornerTopLeft, UrstammStyle.zIndex5]}>
            <UrstammButtonCorner
              cornerStyle={UrstammStyleCornerButton.cornerTopLeftContainer}
              textIconStyle={UrstammStyleCornerButton.textIconTopLeftContainer}
              iconSvg={props.cornerOptions.cornerTopLeft.icon}
              text={props.cornerOptions.cornerTopLeft.text}
              textStyle={UrstammStyleCornerButton.cornerTextStyle}
              subTextStyle={UrstammStyleCornerButton.cornerSubTextStyle}
              testID={'urstamm_top_left_corner_button'}
              onSubmit={props.submitTopLeft}
            />
          </View>
        )}

        {props.cornerOptions.cornerTopRight.showCorner && (
          <View style={[UrstammStyleCornerButton.cornerTopRight, UrstammStyle.zIndex5]}>
            <UrstammButtonCorner
              cornerStyle={UrstammStyleCornerButton.cornerTopRightContainer}
              textIconStyle={UrstammStyleCornerButton.textIconTopRightContainer}
              iconSvg={props.cornerOptions.cornerTopRight.icon}
              text={props.cornerOptions.cornerTopRight.text}
              textStyle={UrstammStyleCornerButton.cornerTextStyle}
              subTextStyle={UrstammStyleCornerButton.cornerSubTextStyle}
              testID={'urstamm_top_right_corner_button'}
              onSubmit={openDrawer}
            />
          </View>
        )}

        {/**Header logo (clickable) + title */}
        <View style={[props.layoutStyle.headerContainer, UrstammStyle.zIndex4]}>
          <UrstammImageLogo
            width={PlatformHelper.normalizeByDevice(60)}
            height={PlatformHelper.normalizeByDevice(60)}
            showText={false}
            navigation={props.navigation}
            loggedUser={rdxUserExtendedMe}
          />

          {(!PlatformHelper.isWeb() ? !inputIsFocused : true) ? (
            <View style={[props.titleSubViewStyle, UrstammStyle.flexCenterY]}>
              {props.title && <View style={UrstammStyle.flexCenterY}>{props.title}</View>}
              {props.subTitle && <View style={UrstammStyle.flexCenterY}>{props.subTitle}</View>}
            </View>
          ) : null}

          {props.bottomTitle && <View style={{ marginBottom: 'auto', marginTop: 'auto' }}>{props.bottomTitle}</View>}
        </View>

        {/**Header information */}
        {props.headerDetails && props.headerDetails.length > 0
          ? props.headerDetails.map((option, idx) => {
              return (
                <View key={idx} style={[props.layoutStyle.headerDetails, UrstammStyle.zIndex3]}>
                  {option.title && (!PlatformHelper.isWeb() ? !inputIsFocused : true) && (
                    <Text
                      style={[
                        UrstammStyle.titleStyle,
                        {
                          fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize)
                        }
                      ]}>
                      {option.title + ': '}
                    </Text>
                  )}
                  {option.value && (!PlatformHelper.isWeb() ? !inputIsFocused : true) && (
                    <Text
                      style={[
                        UrstammStyle.dataStyle,
                        {
                          fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize)
                        }
                      ]}>
                      {truncate(option.value, option.truncateValue ? option.truncateValue : 20)}
                    </Text>
                  )}
                </View>
              );
            })
          : null}
      </View>

      {/**Bottom layout */}
      <View style={[props.layoutStyle.bottomContainer, UrstammStyle.zIndex4]}>
        <View style={props.layoutStyle.bottomSubContainer}>
          {/**View */}
          <View style={[UrstammStyle.flex1]}>{props.view}</View>

          {/**Bottom corner buttons */}
          <View style={[UrstammStyleCornerButton.mBottom]}>
            {props.cornerOptions.cornerBottomLeft.showCorner && (!PlatformHelper.isWeb() ? !inputIsFocused : true) && (
              <View style={[props.layoutStyle.cornerBottomLeft, UrstammStyle.zIndex5]}>
                <UrstammButtonCorner
                  cornerStyle={UrstammStyleCornerButton.cornerBottomLeftContainer}
                  textIconStyle={[UrstammStyleCornerButton.textIconBottomLeftContainer, { marginTop: 32 }]}
                  iconSvg={props.cornerOptions.cornerBottomLeft.icon}
                  text={props.cornerOptions.cornerBottomLeft.text}
                  textStyle={[UrstammStyleCornerButton.cornerTextStyle]}
                  subTextStyle={UrstammStyleCornerButton.cornerSubTextStyle}
                  subText={props.cornerOptions.cornerBottomLeft.subText}
                  testID={'urstamm_bottom_left_corner_button'}
                  onSubmit={props.submitBottomLeft}
                />
              </View>
            )}
            {props.cornerOptions.cornerBottomRight.showCorner && (!PlatformHelper.isWeb() ? !inputIsFocused : true) && (
              <View style={[props.layoutStyle.cornerBottomRight, UrstammStyle.zIndex5]}>
                <UrstammButtonCorner
                  cornerStyle={UrstammStyleCornerButton.cornerBottomRightContainer}
                  textIconStyle={UrstammStyleCornerButton.textIconBottomRightContainer}
                  iconSvg={props.cornerOptions.cornerBottomRight.icon}
                  text={props.cornerOptions.cornerBottomRight.text}
                  textStyle={UrstammStyleCornerButton.cornerTextStyleNoLetterSpacing}
                  subTextStyle={UrstammStyleCornerButton.cornerSubTextStyle}
                  subText={props.cornerOptions.cornerBottomRight.subText}
                  testID={'urstamm_bottom_rigth_corner_button'}
                  onSubmit={props.submitBottomRight}
                />
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

const APPBAR_HEIGHT = Platform.OS === 'ios' ? 35 : 0;

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  appBar: {
    backgroundColor: '#6F8E30',
    height: APPBAR_HEIGHT,
  }
});
