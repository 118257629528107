import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import {
  setIncomingProcessorShippingNoteList,
  setIncomingProcessorShippingNoteListSort,
  setIncomingProcessorShippingNotePage,
  setIncomingProcessorShippingNoteTotalPages,
  incomingProcessorShippingNote
} from '@redux/features/processor-shipping-note/incomingProcessorShippingNoteSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { RootState } from '@redux/store';

import { customProcessorShippingNoteResourceApi, listSize } from '@services/apis/ApiConfiguration';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';
import IncomingProcessorShippingNoteListView from '@components/views/processor-shipping-note/IncomingProcessorShippingNoteListView';
import { ProcessorShippingNote, PageProcessorShippingNoteDTO, CustomProcessorShippingNoteCriteriaCompanyFilterEnum } from '@services/apis/generated';

export default function IncomingProcessorShippingNoteListContainer({ navigation, route }: RootStackScreenProps<'IncomingProcessorShippingNoteList'>) {
  //REDUX
  const rdxProcessorShippingNoteList = useSelector((state: RootState) => state.persistedReducer.incomingProcessorShippingNote.list);
  const rdxProcessorShippingNoteCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.incomingProcessorShippingNote.completeList
  );
  const rdxProcessorShippingNotePage = useSelector((state: RootState) => state.persistedReducer.incomingProcessorShippingNote.page);
  const rdxProcessorShippingNoteTotalPages = useSelector((state: RootState) => state.persistedReducer.incomingProcessorShippingNote.totalPages);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.incomingProcessorShippingNote.sortBy);
  const dispatch = useDispatch();
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  /**
   * Settings for corner buttons
   */
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText:
        rdxSortBy.length > 0 && rdxSortBy[0] ? rdxSortBy[0].text : i18n.t('utility_components.modal.sortBy.date_desc')
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('generics.incoming_goods'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    dispatch(changeLoaderStatus(false));
    getProcessorShippingNoteList(rdxSortBy);
  }, [route.params, rdxSortBy]);

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setIncomingProcessorShippingNoteListSort(orderSelected));
    setShowSortBy(false);
  };

  const navigateToProcessorShippingNoteDetails = (processorShippingNote: ProcessorShippingNote) => {
    UrstammNavigationHelper.navigateToProcessorShippingNoteDetails(navigation, processorShippingNote);
  };

  const getProcessorShippingNoteList = (order?: ButtonSelect[]) => {
    let sortBy =
      order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['creationDate,desc'];
    dispatch(changeLoaderStatus(true));

    let criteriaFilter: CustomProcessorShippingNoteCriteriaCompanyFilterEnum = CustomProcessorShippingNoteCriteriaCompanyFilterEnum.Processor;

    customProcessorShippingNoteResourceApi
      .getPageProcessorShippingNotesForMyCompany({
        sort: sortBy,
        customProcessorShippingNoteCriteria: { currentState: {}, companyFilter: criteriaFilter },
        page: rdxProcessorShippingNotePage,
        size: listSize
      })
      .then((processorShippingNoteList: PageProcessorShippingNoteDTO) => {
        dispatch(changeLoaderStatus(false));
        if (processorShippingNoteList) {
          dispatch(setIncomingProcessorShippingNoteTotalPages(processorShippingNoteList.totalPages));
          dispatch(setIncomingProcessorShippingNoteList(processorShippingNoteList.content));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const navigateToProcessorShippingNoteRegistration = () => {
    UrstammNavigationHelper.navigateToProcessorShippingNoteRegistration(navigation, rdxProcessorShippingNoteList);
  };

  const getMoreItems = (): void => {
    if (rdxProcessorShippingNotePage < rdxProcessorShippingNoteTotalPages - 1) {
      dispatch(setIncomingProcessorShippingNotePage(rdxProcessorShippingNotePage + 1));
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerLogoTitleStart,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitBottomRight={navigateToProcessorShippingNoteRegistration}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomLeft={() => setShowSortBy(true)}
      title={
        <UrstammTitle
          testID={'shipping_note_title'}
          text={i18n.t('generics.incoming_goods')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <IncomingProcessorShippingNoteListView
          navigation={navigation}
          processorShippingNoteList={rdxProcessorShippingNoteList}
          modalVisible={false}
          moreItems={getMoreItems}
          processorShippingNoteSelected={(processorShippingNote: ProcessorShippingNote) => navigateToProcessorShippingNoteDetails(processorShippingNote)}
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
        />
      }
    />
  );
}
