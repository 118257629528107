import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import UrstammModalSortList from '@components/utility-components/modal/UrstammModalSortList';
import { AlertHelper } from '@helpers/AlertHelper';
import { NumberHelper } from '@helpers/NumberHelper';
import { i18n } from '@i18n/i18n';
import { RootState } from '@redux/store';
import { ProcessorBatchDTO, Company, Product, ProductDTO } from '@services/apis/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  getColorByProductState,
  getIconByProductState,
  getTextByProductSpecies,
  getTextByProductState,
  getTextByTreeOrTrunkSpecies
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterProductByNameOrUniqueIdentifier } from '../../../utils/classes/UrstammUtilityFilter';
import {
  getCardTitleByProcessorBatchOrProduct,
  loggedUserIsAProcessor,
  processorBatchIsClosed,
  productChangedProperties,
  productIsClosed,
  truncate
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { UrstammStyle, UrstammStyleList } from '../../../utils/styles/UrstammStyle';
import ProductDetailsView, { ProductImageForm } from './ProductDetailsView';
import {
  CertificationProps,
  CompanyAppearanceProps,
  CompanyProductTypeProps,
  CompanySurfaceProps,
  ProductForm,
  SpeciesProps
} from './ProductRegistrationView';

export default function ProductListView(props: {
  navigation: any;
  productList: Product[];
  processorBatchSelected: ProcessorBatchDTO;
  productSelected: (data: Product) => void;
  moreItems?: () => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
  validate: (product: Product) => void;
  processorList: Company[];
  certificationProps?: CertificationProps;
  speciesProps?: SpeciesProps;
  companyProductTypeProps?: CompanyProductTypeProps;
  companySurfaceProps?: CompanySurfaceProps;
  companyAppearanceProps?: CompanyAppearanceProps;
  submitProductForm: (data: ProductForm, productPhoto?: ProductImageForm) => void;
  closeModalClosingProduct: boolean;
  cloneProduct?: (product: Product | ProductDTO) => void;
}) {
  const productFilter = useSelector((state: RootState) => state.persistedReducer.productFilter.value);
  const rdxProductSortBy = useSelector((state: RootState) => state.persistedReducer.product.sortBy);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const dispatch = useDispatch();

  const [showProductModal, setShowProductModal] = useState<boolean>(false);
  const [productClosed, setProductClosed] = useState<Product>();
  const [productToValidate, setProductToValidate] = useState();

  useEffect(() => {
    if (props.closeModalClosingProduct) setShowProductModal(!showProductModal);
  }, [props.closeModalClosingProduct]);

  const listItemProps = useMemo(() => {
    if (loggedUserIsAProcessor(rdxUserExtendedMe) && !processorBatchIsClosed(props.processorBatchSelected)) {
      return {
        actions: {
          'bottom-right': {
            action: (product: Product) => props.cloneProduct?.(product),
            icon: 'copy',
            iconProps: {
              iconColor: ColorTheme.mediumGrey,
            }
          }
        }
      }
    }
    return { actions: {} };
  }, [props.processorBatchSelected]);

  let keys: ListSubData[] = [
    {
      title: i18n.t('generics.productType') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productType',
      secondKey: 'name',
      keyId: '1productType',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    // {
    //   title: i18n.t('generics.sawmill') + ':',
    //   titleTextStyle: UrstammStyleList.cardTitleTextStyle,
    //   key: 'sawmill',
    //   secondKey: 'name',
    //   keyId: '1sawmill',
    //   dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    // },
    {
      title: i18n.t('generics.processor') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      secondKey: 'name',
      keyId: '1processor',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' },
      isEnum: true
    },
    {
      title: i18n.t('generics.specie') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productSpecies',
      keyId: '1productSpecies',
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' },
      isEnum: true
    },
    {
      title: i18n.t('views.processor_batch.sum_volume_products') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      // dataContainerViewStyle: { flexBasis: '100%' }
    }
  ];

  const getActiveProductCurrentState = () => {
    if (productFilter.length > 0) return productFilter.filter(val => val.active);
  };

  const isProductFieldHiddenFunction = (item, key) => {
    const { sumCubage = null } = item
    const sumCubageDeps = ['processor', 'productSpecies', 'productType'];
    if (NumberHelper.roundWithThreeDecimals(sumCubage) === 0 && sumCubageDeps.includes(key)) {
      return true;
    }
    return false;
  }

  return (
    <View>
      <UrstammList
        showHash={false}
        elementSelected={(productSelected: Product) => props.productSelected(productSelected)}
        testID={'product_list'}
        titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
        textForEmptyList={i18n.t('views.product.products_not_found')}
        list={props.productList}
        listStyle={{
          mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
          mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
          mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
          bottomContainerViewStyle: UrstammStyleList.cardSubData,
          bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
        }}
        listSubData={keys}
        filterBy={getActiveProductCurrentState()}
        currentState={{
          icon: state => getIconByProductState(state),
          color: state => getColorByProductState(state),
          text: state => getTextByProductState(state)
        }}
        isFieldHiddenFunction={isProductFieldHiddenFunction}
        filterList={true}
        filterListFunction={(text, data) => filterProductByNameOrUniqueIdentifier(text, data, rdxUserExtendedMe)}
        elementSwitched={data => {
          props.validate(data.trunk);
          setShowProductModal(true);
          setProductClosed(data.trunk);
        }}
        //getTextForData={(data) => ...(data)} isEnum?
        switchOptions={{
          enabled: true,
          textStyle: UrstammStyle.switchStyle,
          statusByFn: (product: Product) => productChangedProperties(product, rdxUserExtendedMe.type),
          colorByFn: (product: Product) => getColorByProductState(product.currentState),
          textByFn: (product: Product) => getTextByProductState(product.currentState),
          disabledByFn: (product: Product) => productIsClosed(product),
          switchConfirm: (product: Product) => productChangedProperties(product, rdxUserExtendedMe.type)
        }}
        getTextForData={(data, key) =>
            key == 'productSpecies'
            ? truncate(getTextByProductSpecies(data), 24)
            : key == 'species'
            ? getTextByTreeOrTrunkSpecies(data)
            : key == 'sumCubage'
            ? NumberHelper.roundWithThreeDecimals(data?.sumCubage)
            : null
        }
        touchSwitch={() => setShowProductModal(true)}
        listItemProps={listItemProps}
      />

      {props.showSortBy ? (
        <UrstammModalSortList
          testID={'modal_sort'}
          showSortBy={props.showSortBy}
          closeShowSortBy={props.closeShowSortBy}
          applyOrder={(orderSelected: ButtonSelect[]) => props.applyOrder(orderSelected)}
          sortBySelected={rdxProductSortBy}
        />
      ) : null}

      {showProductModal ? (
        <UrstammModalSelect
          testID={'modal_closing_product_multiple_select'}
          visible={showProductModal}
          text={{
            textInputTitle: i18n.t('generics.product') + ': ' + productClosed?.name!,
            textStyle: UrstammStyle.textTitle
          }}
          closeModal={() => {
            setShowProductModal(false);
          }}
          //confirmButton={{ enabled: true, text: i18n.t('generics.save'), submitValue: () => { setShowProductModal(false) } }}
          listJSX={
            <ProductDetailsView
              navigation={props.navigation}
              processorBatchDTO={props.processorBatchSelected}
              productSelected={productClosed!}
              processorList={props.processorList}
              closingProduct={true}
              certificationProps={props.certificationProps}
              speciesProps={props.speciesProps}
              companySurfaceProps={props.companySurfaceProps}
              submitProductForm={props.submitProductForm}
              companyProductTypeProps={props.companyProductTypeProps}
              companyAppearanceProps={props.companyAppearanceProps}
            />
          }
        />
      ) : null}
    </View>
  );
}
