import { AntDesign } from '@expo/vector-icons';
import { resetInputFocus, setInputFocus } from '@redux/features/utility/inputSlice';
import React, { useEffect, useRef, useState } from 'react';
import {
  Keyboard,
  KeyboardType,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle
} from 'react-native';
import { useDispatch } from 'react-redux';
import { getMandatorySymbol } from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { UrstammStyle } from '../../../utils/styles/UrstammStyle';
import UrstammIcon, { UrstammIconProps } from '../icon/UrstammIcon';
export interface UrstammInputStyle {
  containerViewStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

export default function UrstammInput(props: {
  testID: string;
  style?: UrstammInputStyle;
  icon?: UrstammIconProps;
  placeholder?: string;
  defaultValue?: string;
  onChangeText: any;
  secureTextEntry?: boolean;
  textInputTitle?: string;
  mandatory?: boolean;
  multiline?: boolean;
  value?: string;
  keyboardType?: KeyboardType;
  editable?: boolean;
  maxLength?: number;
}) {
  const localInputRef = useRef<TextInput>();

  const keyboardDidHideCallback = () => {
    localInputRef?.current?.blur?.();
  };

  useEffect(() => {
    const keyboardDidHideSubscription = Keyboard.addListener('keyboardDidHide', keyboardDidHideCallback);

    return () => {
      keyboardDidHideSubscription?.remove();
    };
  }, []);

  const [passwordVisibility, setPasswordVisibility] = useState(true);

  const dispatch = useDispatch();

  const changeVisibility = (): void => {
    setPasswordVisibility(!passwordVisibility);
  };

  return (
    <View style={UrstammStyle.flexGrow1}>
      {/**Title */}
      {props.textInputTitle && props.textInputTitle.length > 0 && (
        <View style={UrstammStyle.textTitleContainerFlex}>
          <Text style={UrstammStyle.textTitle}>{props.textInputTitle + getMandatorySymbol(props.mandatory!)}</Text>
        </View>
      )}
      {/**Text input */}
      <View style={[props.style?.containerViewStyle, UrstammStyle.baseShadow]}>
        {props.icon?.showIcon && (
          <View style={UrstammStyle.iconContainer}>
            <UrstammIcon name={props.icon?.iconName} size={24} color={props.icon.iconColor!} type="FontAwesome5" />
          </View>
        )}
        <View style={UrstammStyle.flex1}>
          <TextInput
            ref={ref => {
              localInputRef && (localInputRef.current = ref as any);
            }}
            testID={props.testID}
            value={props.value}
            placeholder={props.placeholder}
            secureTextEntry={!props.secureTextEntry ? props.secureTextEntry : passwordVisibility}
            style={[UrstammStyle.flex1, props.style?.textStyle]}
            onChangeText={data => props.onChangeText(data)}
            multiline={props.multiline}
            editable={props.editable}
            defaultValue={props.defaultValue}
            selectTextOnFocus={props.editable}
            contextMenuHidden={props.editable}
            maxLength={props.maxLength}
            keyboardType={props.keyboardType ? props.keyboardType : 'default'}
            onFocus={() => {
              dispatch(setInputFocus(true));
            }}
            onBlur={() => dispatch(resetInputFocus())}
            blurOnSubmit={true}
          />
        </View>

        {/**Check password */}
        {props.secureTextEntry && (
          <AntDesign
            style={UrstammStyle.alignSelfFlexEnd}
            name="eye"
            size={24}
            color={ColorTheme.lightGreen}
            onPress={() => changeVisibility()}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
