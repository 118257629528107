import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import UrstammModalSortList from '@components/utility-components/modal/UrstammModalSortList';
import { NumberHelper } from '@helpers/NumberHelper';
import { i18n } from '@i18n/i18n';
import { RootState } from '@redux/store';
import { BatchDTO, Company, Packet, PacketDTO } from '@services/apis/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  getColorByPacketState,
  getIconByPacketState,
  getTextByPacketSpecies,
  getTextByPacketState,
  getTextByTreeOrTrunkSpecies
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterPacketByNameOrUniqueIdentifier } from '../../../utils/classes/UrstammUtilityFilter';
import {
  batchIsClosed,
  getCardTitleByBatchOrPacket,
  isCompanyMock,
  loggedUserIsASawmill,
  packetChangedProperties,
  packetIsEditable,
  truncate
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { UrstammStyle, UrstammStyleList } from '../../../utils/styles/UrstammStyle';
import PacketDetailsView, { PacketImageForm } from './PacketDetailsView';
import {
  CertificationProps,
  CompanyProductTypeProps,
  CompanyPacketWoodQualityProps,
  CompanySurfaceProps,
  PacketForm,
  SpeciesProps,
  TrunkProps
} from './PacketRegistrationView';

export default function PacketListView(props: {
  navigation: any;
  packetList: Packet[];
  batchSelected: BatchDTO;
  packetSelected: (data: Packet) => void;
  moreItems?: () => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
  validate: (packet: Packet) => void;
  processorList: Company[];
  certificationProps?: CertificationProps;
  speciesProps?: SpeciesProps;
  trunkProps?: TrunkProps;
  companyProductTypeProps?: CompanyProductTypeProps;
  companyPacketWoodQualityProps?: CompanyPacketWoodQualityProps;
  companySurfaceProps?: CompanySurfaceProps;
  submitPacketForm: (data: PacketForm, packetPhoto?: PacketImageForm) => void;
  closeModalClosingPacket: boolean;
  clonePacket?: (packet: Packet | PacketDTO) => void;
}) {
  const packetFilter = useSelector((state: RootState) => state.persistedReducer.packetFilter.value);
  const rdxPacketSortBy = useSelector((state: RootState) => state.persistedReducer.packet.sortBy);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const dispatch = useDispatch();

  const [showPacketModal, setShowPacketModal] = useState<boolean>(false);
  const [packetClosed, setPacketClosed] = useState<Packet>();
  const [packetToValidate, setPacketToValidate] = useState();

  useEffect(() => {
    if (props.closeModalClosingPacket) setShowPacketModal(!showPacketModal);
  }, [props.closeModalClosingPacket]);

  const listItemProps = useMemo(() => {
    if (loggedUserIsASawmill(rdxUserExtendedMe) && !batchIsClosed(props.batchSelected)) {
      return {
        actions: {
          'bottom-right': {
            action: (packet: Packet) => props.clonePacket?.(packet),
            icon: 'copy',
            iconProps: {
              iconColor: ColorTheme.mediumGrey,
            }
          }
        }
      }
    }
    return { actions: {} };
  }, [props.batchSelected]);

  let keys: ListSubData[] = [
    {
      title: i18n.t('generics.productType') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productType',
      secondKey: 'name',
      keyId: '1productType',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    // {
    //   title: i18n.t('generics.sawmill') + ':',
    //   titleTextStyle: UrstammStyleList.cardTitleTextStyle,
    //   key: 'sawmill',
    //   secondKey: 'name',
    //   keyId: '1sawmill',
    //   dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    // },
    {
      title: i18n.t('generics.processor') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      secondKey: 'name',
      keyId: '1processor',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' },
      truncateMaxLength: 16,
      isEnum: true
    },
    {
      title: i18n.t('generics.specie') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'packetSpecies',
      keyId: '1packetSpecies',
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' },
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' },
      isEnum: true
    },
    {
      title: i18n.t('views.batch.sum_volume_packets') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      dataContainerViewStyle: { height: 24, flexBasis: '100%' }
    }
  ];

  const getActivePacketCurrentState = () => {
    if (packetFilter.length > 0) return packetFilter.filter(val => val.active);
  };

  return (
    <View>
      <UrstammList
        showHash={false}
        elementSelected={(packetSelected: Packet) => props.packetSelected(packetSelected)}
        testID={'packet_list'}
        titleByFunction={data => getCardTitleByBatchOrPacket(data)}
        textForEmptyList={i18n.t('views.packet.packets_not_found')}
        list={props.packetList}
        listStyle={{
          mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
          mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
          mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
          bottomContainerViewStyle: UrstammStyleList.cardSubData,
          bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
        }}
        listSubData={keys}
        filterBy={getActivePacketCurrentState()}
        currentState={{
          icon: state => getIconByPacketState(state),
          color: state => getColorByPacketState(state),
          text: state => getTextByPacketState(state)
        }}
        filterList={true}
        filterListFunction={(text, data) => filterPacketByNameOrUniqueIdentifier(text, data, rdxUserExtendedMe)}
        elementSwitched={data => {
          props.validate(data.trunk);
          setShowPacketModal(true);
          setPacketClosed(data.trunk);
        }}
        //getTextForData={(data) => ...(data)} isEnum?
        switchOptions={{
          enabled: true,
          textStyle: UrstammStyle.switchStyle,
          statusByFn: (packet: Packet) => packetChangedProperties(packet, rdxUserExtendedMe.type),
          colorByFn: (packet: Packet) => getColorByPacketState(packet.currentState),
          textByFn: (packet: Packet) => getTextByPacketState(packet.currentState),
          disabledByFn: (packet: Packet) => !packetIsEditable(packet),
          switchConfirm: (packet: Packet) => packetChangedProperties(packet, rdxUserExtendedMe.type)
        }}
        getTextForData={(data, key) =>
          key == 'packetSpecies'
            ? truncate(getTextByPacketSpecies(data), 24)
            : key == 'species'
            ? getTextByTreeOrTrunkSpecies(data)
            : key == 'sumCubage'
            ? NumberHelper.roundWithThreeDecimals(data?.sumCubage)
            : null
        }
        touchSwitch={() => setShowPacketModal(true)}
        listItemProps={listItemProps}
      />

      {props.showSortBy ? (
        <UrstammModalSortList
          testID={'modal_sort'}
          showSortBy={props.showSortBy}
          closeShowSortBy={props.closeShowSortBy}
          applyOrder={(orderSelected: ButtonSelect[]) => props.applyOrder(orderSelected)}
          sortBySelected={rdxPacketSortBy}
        />
      ) : null}

      {showPacketModal ? (
        <UrstammModalSelect
          testID={'modal_closing_packet_multiple_select'}
          visible={showPacketModal}
          text={{
            textInputTitle: i18n.t('generics.packet') + ': ' + packetClosed?.name!,
            textStyle: UrstammStyle.textTitle
          }}
          closeModal={() => {
            setShowPacketModal(false);
          }}
          //confirmButton={{ enabled: true, text: i18n.t('generics.save'), submitValue: () => { setShowPacketModal(false) } }}
          listJSX={
            <PacketDetailsView
              navigation={props.navigation}
              batchDTO={props.batchSelected}
              packetSelected={packetClosed!}
              processorList={props.processorList}
              closingPacket={true}
              certificationProps={props.certificationProps}
              speciesProps={props.speciesProps}
              trunkProps={props.trunkProps}
              companyPacketWoodQualityProps={props.companyPacketWoodQualityProps}
              companySurfaceProps={props.companySurfaceProps}
              submitPacketForm={props.submitPacketForm}
              companyProductTypeProps={props.companyProductTypeProps}
            />
          }
        />
      ) : null}
    </View>
  );
}
