import { AlertHelper } from '@helpers/AlertHelper';
import { NotificationHelper } from '@helpers/NotificationHelper';
import { logout, readAccessToken } from '@helpers/StorageHelper';
import '@react-native-anywhere/polyfill-base64';
import { reloadApiConfiguration } from '@services/apis/ApiConfiguration';
import { BaseAPIManager } from '@services/model/BaseAPIManager';
import React, { useEffect, useRef } from 'react';
import { KeyboardAvoidingView, Platform, StatusBar, StyleSheet } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import useCachedResources from './app/hooks/useCachedResources';
import UrstammNavigation from './app/navigation/UrstammNavigation';
import { persistor, store } from './app/redux/store';
import { UrstammStyle } from './app/utils/styles/UrstammStyle';

import OfflineSyncManager from '@components/utility-components/offlineSync/OfflineSyncManager';
import { environment } from '@environments/environment';
import Loader from '@helpers/LoaderHelper';
import * as Location from 'expo-location';

export default function App() {
  let offlineIntervalId;
  const offlineSyncTimerMs = 30000;

  const offlineSyncManagerRef = useRef<any>();
  const isLoadingComplete = useCachedResources();

  useEffect(() => {
    offlineIntervalId = setInterval(() => {
      if (environment.enableOfflineSync) offlineSyncManagerRef.current!.startUploadSync(true);
    }, offlineSyncTimerMs);

    setup();
    (async () => {
      if (Platform.OS != 'web') {
        let permissions = await Location.requestForegroundPermissionsAsync();
        if (!permissions.granted) {
          AlertHelper.showSimpleAlert('Error', 'location_is_required', () => {
            throw new Error('missing location');
          });
          return;
        }

        if (permissions.android && permissions.android?.accuracy != 'fine') {
          AlertHelper.showSimpleAlert('Error', 'location_fine_is_required', () => {
            throw new Error('missing fine location for android');
          });
          return;
        }
      }
    })();
    return function cleanup() {
      console.log('cleaning up');
      clearInterval(offlineIntervalId);
    };
  }, []);

  const setup = async () => {
    BaseAPIManager.contextLoadingCallback = function (loading: boolean) {
      //setLoading(loading);
    };
    BaseAPIManager.logoutCallback = function () {
      logout();
    };
    reloadApiConfiguration();
    let token = await readAccessToken();
    if (token != null) {
      BaseAPIManager.setToken(token);
      NotificationHelper.registerForPushNotificationsAsync();
    }
  };

  reloadApiConfiguration();

  const showAlert = () => {
    AlertHelper.showSimpleErrorAlert();
  };

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <Provider store={store}>
          <StatusBar backgroundColor="#597227" barStyle={"light-content"} />
          <PersistGate loading={null} persistor={persistor}>
            <OfflineSyncManager ref={offlineSyncManagerRef} />
            <Loader></Loader>
            <KeyboardAvoidingView
              style={UrstammStyle.flex1}
              keyboardVerticalOffset={0}
              behavior={Platform.select({
                android: undefined,
                ios: 'padding'
              })}
              enabled>
              <UrstammNavigation />
            </KeyboardAvoidingView>
          </PersistGate>
        </Provider>
      </SafeAreaProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
