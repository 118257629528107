/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProcessorBatchDTO
 */
export interface ProcessorBatchDTO {
    /**
     * 
     * @type {number}
     * @memberof ProcessorBatchDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatchDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatchDTO
     */
    uniqueIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatchDTO
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProcessorBatchDTO
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatchDTO
     */
    recordingType?: ProcessorBatchDTORecordingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ProcessorBatchDTO
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatchDTO
     */
    currentState?: ProcessorBatchDTOCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatchDTO
     */
    uuid?: string;
    /**
     * 
     * @type {Company}
     * @memberof ProcessorBatchDTO
     */
    processor?: Company;
    /**
     * 
     * @type {Set<Product>}
     * @memberof ProcessorBatchDTO
     */
    sourceProducts?: Set<Product>;
    /**
     * 
     * @type {Set<Product>}
     * @memberof ProcessorBatchDTO
     */
    products?: Set<Product>;
}


/**
 * @export
 */
export const ProcessorBatchDTORecordingTypeEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE',
    Date: 'DATE',
} as const;
export type ProcessorBatchDTORecordingTypeEnum = typeof ProcessorBatchDTORecordingTypeEnum[keyof typeof ProcessorBatchDTORecordingTypeEnum];

/**
 * @export
 */
export const ProcessorBatchDTOCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProcessorBatchDTOCurrentStateEnum = typeof ProcessorBatchDTOCurrentStateEnum[keyof typeof ProcessorBatchDTOCurrentStateEnum];


/**
 * Check if a given object implements the ProcessorBatchDTO interface.
 */
export function instanceOfProcessorBatchDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProcessorBatchDTOFromJSON(json: any): ProcessorBatchDTO {
    return ProcessorBatchDTOFromJSONTyped(json, false);
}

export function ProcessorBatchDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessorBatchDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'uniqueIdentifier': !exists(json, 'uniqueIdentifier') ? undefined : json['uniqueIdentifier'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'recordingType': !exists(json, 'recordingType') ? undefined : json['recordingType'],
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'processor': !exists(json, 'processor') ? undefined : CompanyFromJSON(json['processor']),
        'sourceProducts': !exists(json, 'sourceProducts') ? undefined : (new Set((json['sourceProducts'] as Array<any>).map(ProductFromJSON))),
        'products': !exists(json, 'products') ? undefined : (new Set((json['products'] as Array<any>).map(ProductFromJSON))),
    };
}

export function ProcessorBatchDTOToJSON(value?: ProcessorBatchDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'uniqueIdentifier': value.uniqueIdentifier,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'recordingType': value.recordingType,
        'sumCubage': value.sumCubage,
        'currentState': value.currentState,
        'uuid': value.uuid,
        'processor': CompanyToJSON(value.processor),
        'sourceProducts': value.sourceProducts === undefined ? undefined : (Array.from(value.sourceProducts as Set<any>).map(ProductToJSON)),
        'products': value.products === undefined ? undefined : (Array.from(value.products as Set<any>).map(ProductToJSON)),
    };
}

