/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { CompanyAppearance } from './CompanyAppearance';
import {
    CompanyAppearanceFromJSON,
    CompanyAppearanceFromJSONTyped,
    CompanyAppearanceToJSON,
} from './CompanyAppearance';
import type { CompanyProductType } from './CompanyProductType';
import {
    CompanyProductTypeFromJSON,
    CompanyProductTypeFromJSONTyped,
    CompanyProductTypeToJSON,
} from './CompanyProductType';
import type { CompanySurface } from './CompanySurface';
import {
    CompanySurfaceFromJSON,
    CompanySurfaceFromJSONTyped,
    CompanySurfaceToJSON,
} from './CompanySurface';
import type { Packet } from './Packet';
import {
    PacketFromJSON,
    PacketFromJSONTyped,
    PacketToJSON,
} from './Packet';
import type { ProcessorBatch } from './ProcessorBatch';
import {
    ProcessorBatchFromJSON,
    ProcessorBatchFromJSONTyped,
    ProcessorBatchToJSON,
} from './ProcessorBatch';
import type { ProcessorShippingNote } from './ProcessorShippingNote';
import {
    ProcessorShippingNoteFromJSON,
    ProcessorShippingNoteFromJSONTyped,
    ProcessorShippingNoteToJSON,
} from './ProcessorShippingNote';
import type { ProductCertification } from './ProductCertification';
import {
    ProductCertificationFromJSON,
    ProductCertificationFromJSONTyped,
    ProductCertificationToJSON,
} from './ProductCertification';
import type { ProductSpecies } from './ProductSpecies';
import {
    ProductSpeciesFromJSON,
    ProductSpeciesFromJSONTyped,
    ProductSpeciesToJSON,
} from './ProductSpecies';
import type { ProductState } from './ProductState';
import {
    ProductStateFromJSON,
    ProductStateFromJSONTyped,
    ProductStateToJSON,
} from './ProductState';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    uniqueIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    processorCustomName?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    currentState: ProductCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    type?: ProductTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    photoFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    photoFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    photoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    photoMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    uuid?: string;
    /**
     * 
     * @type {ProcessorBatch}
     * @memberof Product
     */
    processorBatch?: ProcessorBatch;
    /**
     * 
     * @type {Product}
     * @memberof Product
     */
    specificIncomingProduct?: Product;
    /**
     * 
     * @type {Company}
     * @memberof Product
     */
    processor?: Company;
    /**
     * 
     * @type {CompanyAppearance}
     * @memberof Product
     */
    appearance?: CompanyAppearance;
    /**
     * 
     * @type {CompanySurface}
     * @memberof Product
     */
    surface?: CompanySurface;
    /**
     * 
     * @type {CompanyProductType}
     * @memberof Product
     */
    productType?: CompanyProductType;
    /**
     * 
     * @type {User}
     * @memberof Product
     */
    photoAuthor?: User;
    /**
     * 
     * @type {Packet}
     * @memberof Product
     */
    referencePacket?: Packet;
    /**
     * 
     * @type {Project}
     * @memberof Product
     */
    project?: Project;
    /**
     * 
     * @type {Set<ProcessorShippingNote>}
     * @memberof Product
     */
    incomingShippingNotes?: Set<ProcessorShippingNote>;
    /**
     * 
     * @type {Set<Product>}
     * @memberof Product
     */
    specificOutgoingProducts?: Set<Product>;
    /**
     * 
     * @type {Set<ProductCertification>}
     * @memberof Product
     */
    productCertifications?: Set<ProductCertification>;
    /**
     * 
     * @type {Set<ProductSpecies>}
     * @memberof Product
     */
    productSpecies?: Set<ProductSpecies>;
    /**
     * 
     * @type {Set<ProductState>}
     * @memberof Product
     */
    productStates?: Set<ProductState>;
    /**
     * 
     * @type {Set<ProcessorBatch>}
     * @memberof Product
     */
    usingProcessorBatches?: Set<ProcessorBatch>;
    /**
     * 
     * @type {Set<ProcessorShippingNote>}
     * @memberof Product
     */
    outgoingShippingNotes?: Set<ProcessorShippingNote>;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    photoCreationDate?: Date;
}


/**
 * @export
 */
export const ProductCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED',
    SentInShippingNote: 'SENT_IN_SHIPPING_NOTE'
} as const;
export type ProductCurrentStateEnum = typeof ProductCurrentStateEnum[keyof typeof ProductCurrentStateEnum];

/**
 * @export
 */
export const ProductTypeEnum = {
    Packet: 'PACKET',
    PreProduct: 'PRE_PRODUCT',
    EndProduct: 'END_PRODUCT'
} as const;
export type ProductTypeEnum = typeof ProductTypeEnum[keyof typeof ProductTypeEnum];


/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "uniqueIdentifier" in value;
    isInstance = isInstance && "currentState" in value;

    return isInstance;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'uniqueIdentifier': json['uniqueIdentifier'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'processorCustomName': !exists(json, 'processorCustomName') ? undefined : json['processorCustomName'],
        'currentState': json['currentState'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'photoFilename': !exists(json, 'photoFilename') ? undefined : json['photoFilename'],
        'photoFilepath': !exists(json, 'photoFilepath') ? undefined : json['photoFilepath'],
        'photoUrl': !exists(json, 'photoUrl') ? undefined : json['photoUrl'],
        'photoMd5': !exists(json, 'photoMd5') ? undefined : json['photoMd5'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'processorBatch': !exists(json, 'processorBatch') ? undefined : ProcessorBatchFromJSON(json['processorBatch']),
        'specificIncomingProduct': !exists(json, 'specificIncomingProduct') ? undefined : ProductFromJSON(json['specificIncomingProduct']),
        'processor': !exists(json, 'processor') ? undefined : CompanyFromJSON(json['processor']),
        'appearance': !exists(json, 'appearance') ? undefined : CompanyAppearanceFromJSON(json['appearance']),
        'surface': !exists(json, 'surface') ? undefined : CompanySurfaceFromJSON(json['surface']),
        'productType': !exists(json, 'productType') ? undefined : CompanyProductTypeFromJSON(json['productType']),
        'photoAuthor': !exists(json, 'photoAuthor') ? undefined : UserFromJSON(json['photoAuthor']),
        'referencePacket': !exists(json, 'referencePacket') ? undefined : PacketFromJSON(json['referencePacket']),
        'project': !exists(json, 'project') ? undefined : ProjectFromJSON(json['project']),
        'incomingShippingNotes': !exists(json, 'incomingShippingNotes') ? undefined : (new Set((json['incomingShippingNotes'] as Array<any>).map(ProcessorShippingNoteFromJSON))),
        'specificOutgoingProducts': !exists(json, 'specificOutgoingProducts') ? undefined : (new Set((json['specificOutgoingProducts'] as Array<any>).map(ProductFromJSON))),
        'productCertifications': !exists(json, 'productCertifications') ? undefined : (new Set((json['productCertifications'] as Array<any>).map(ProductCertificationFromJSON))),
        'productSpecies': !exists(json, 'productSpecies') ? undefined : (new Set((json['productSpecies'] as Array<any>).map(ProductSpeciesFromJSON))),
        'productStates': !exists(json, 'productStates') ? undefined : (new Set((json['productStates'] as Array<any>).map(ProductStateFromJSON))),
        'usingProcessorBatches': !exists(json, 'usingProcessorBatches') ? undefined : (new Set((json['usingProcessorBatches'] as Array<any>).map(ProcessorBatchFromJSON))),
        'outgoingShippingNotes': !exists(json, 'outgoingShippingNotes') ? undefined : (new Set((json['outgoingShippingNotes'] as Array<any>).map(ProcessorShippingNoteFromJSON))),
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'uniqueIdentifier': value.uniqueIdentifier,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'sumCubage': value.sumCubage,
        'processorCustomName': value.processorCustomName,
        'currentState': value.currentState,
        'type': value.type,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'photoFilename': value.photoFilename,
        'photoFilepath': value.photoFilepath,
        'photoUrl': value.photoUrl,
        'photoMd5': value.photoMd5,
        'uuid': value.uuid,
        'processorBatch': ProcessorBatchToJSON(value.processorBatch),
        'specificIncomingProduct': ProductToJSON(value.specificIncomingProduct),
        'processor': CompanyToJSON(value.processor),
        'appearance': CompanyAppearanceToJSON(value.appearance),
        'surface': CompanySurfaceToJSON(value.surface),
        'productType': CompanyProductTypeToJSON(value.productType),
        'photoAuthor': UserToJSON(value.photoAuthor),
        'referencePacket': PacketToJSON(value.referencePacket),
        'project': ProjectToJSON(value.project),
        'incomingShippingNotes': value.incomingShippingNotes === undefined ? undefined : (Array.from(value.incomingShippingNotes as Set<any>).map(ProcessorShippingNoteToJSON)),
        'specificOutgoingProducts': value.specificOutgoingProducts === undefined ? undefined : (Array.from(value.specificOutgoingProducts as Set<any>).map(ProductToJSON)),
        'productCertifications': value.productCertifications === undefined ? undefined : (Array.from(value.productCertifications as Set<any>).map(ProductCertificationToJSON)),
        'productSpecies': value.productSpecies === undefined ? undefined : (Array.from(value.productSpecies as Set<any>).map(ProductSpeciesToJSON)),
        'productStates': value.productStates === undefined ? undefined : (Array.from(value.productStates as Set<any>).map(ProductStateToJSON)),
        'usingProcessorBatches': value.usingProcessorBatches === undefined ? undefined : (Array.from(value.usingProcessorBatches as Set<any>).map(ProcessorBatchToJSON)),
        'outgoingShippingNotes': value.outgoingShippingNotes === undefined ? undefined : (Array.from(value.outgoingShippingNotes as Set<any>).map(ProcessorShippingNoteToJSON)),
        'photoCreationDate': value.photoCreationDate === undefined ? undefined : value.photoCreationDate,
    };
}

