import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import BatchListView from '@components/views/batch/BatchListView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { setBatchList, setBatchListSort, setBatchPage, setBatchTotalPages } from '@redux/features/batch/batchSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { RootState } from '@redux/store';
import { customBatchResourceApi, listSize } from '@services/apis/ApiConfiguration';
import { Batch, PageBatch } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function BatchListContainer({ navigation, route }: RootStackScreenProps<'BatchList'>) {
  //REDUX
  const rdxBatchList = useSelector((state: RootState) => state.persistedReducer.batch.list);
  const rdxBatchCompleteList = useSelector((state: RootState) => state.persistedReducer.batch.completeList);
  const rdxBatchPage = useSelector((state: RootState) => state.persistedReducer.batch.page);
  const rdxBatchTotalPages = useSelector((state: RootState) => state.persistedReducer.batch.totalPages);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.batch.sortBy);
  const dispatch = useDispatch();
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  /**
   * Settings for corner buttons
   */
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText:
        rdxSortBy.length > 0 && rdxSortBy[0] ? rdxSortBy[0].text : i18n.t('utility_components.modal.sortBy.date_desc')
    },
    cornerBottomRight: {
      showCorner: true,
      text: i18n.t('generics.batch'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    dispatch(changeLoaderStatus(false));
    getBatchList(rdxSortBy);
  }, [route.params, rdxSortBy]);

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setBatchListSort(orderSelected));
    setShowSortBy(false);
  };

  const navigateToBatchDetails = (batch: Batch) => {
    UrstammNavigationHelper.navigateToBatchDetails(navigation, batch);
  };

  const getBatchList = (order?: ButtonSelect[]) => {
    let sortBy =
      order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['creationDate,desc'];
    dispatch(changeLoaderStatus(true));

    customBatchResourceApi
      .getPageBatchesForMyCompany({
        sort: sortBy,
        customBatchCriteria: { currentState: {} },
        page: rdxBatchPage,
        size: listSize
      })
      .then((batchList: PageBatch) => {
        dispatch(changeLoaderStatus(false));
        if (batchList) {
          dispatch(setBatchTotalPages(batchList.totalPages));
          dispatch(setBatchList(batchList.content));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const navigateToBatchRegistration = () => {
    UrstammNavigationHelper.navigateToBatchRegistration(navigation, rdxBatchList);
  };

  const getMoreItems = (): void => {
    if (rdxBatchPage < rdxBatchTotalPages - 1) {
      dispatch(setBatchPage(rdxBatchPage + 1));
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerLogoTitleStart,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitBottomRight={navigateToBatchRegistration}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomLeft={() => setShowSortBy(true)}
      title={
        <UrstammTitle
          testID={'batch_title'}
          text={i18n.t('generics.batch')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <BatchListView
          navigation={navigation}
          batchList={rdxBatchList}
          modalVisible={false}
          moreItems={getMoreItems}
          batchSelected={(batch: Batch) => navigateToBatchDetails(batch)}
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
        />
      }
    />
  );
}
