import { i18n } from '@i18n/i18n';
import React, { useState } from 'react';
import { Modal, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View } from 'react-native';
import Layout from '../../../config/Layout';
import { getMandatorySymbol } from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';
import UrstammButtonBase from '../button/UrstammButtonBase';
import UrstammIcon, { UrstammIconProps } from '../icon/UrstammIcon';
import UrstammInput from '../input/UrstammInput';

export interface ModalSelectTextOptions {
  textInputTitle: string;
  textInputSubTitle?: string;
  textStyle?: StyleProp<TextStyle>;
  textSubTitleStyle?: StyleProp<TextStyle>;
}

export interface ModalSelectConfirmButtonOptions {
  enabled: boolean;
  text: string;
  disabled?: boolean;
  submitValue: (open?: boolean) => void; //TODO
}

export interface ModalSize {
  mainContainerHeight?: number;
  mainContainerWidth?: number;
  secondaryContainerHeight?: number;
  secondaryContainerWidth?: number;
}

/**
 * The function is used for multiple select (component: UrstammModalSelect)
 * @param listUpdated  (useStateWithCallbackLazy)
 * @param setState
 * @param setReferenceState
 * @param listSelected Elements selected
 */
export const deepCopyAndAddSelectedParameter = (
  listUpdated,
  setState: any,
  setReferenceState: any,
  listSelected?: any[]
) => {
  let list = JSON.parse(JSON.stringify(listUpdated));
  list = list.map(val => ({
    ...val,
    selected: false
  }));
  if (listSelected && listSelected?.length > 0) {
    listSelected?.forEach(elemSelected => {
      for (let val of list) {
        if (val.id == elemSelected.id) {
          val.selected = elemSelected.id == val.id;
          break;
        }
      }
    });
  }

  const cloneList = JSON.parse(JSON.stringify(list));
  setState(list);
  setReferenceState(cloneList);
};

export const selectElementFormList = (dataSelected, setState: any, state) => {
  let list = JSON.parse(JSON.stringify(state));
  list.forEach(val => {
    if (val.id == dataSelected.item.id) {
      val.selected = !dataSelected.item.selected;
    }
  });
  setState(list);
};

export const selectSlideElementFormList = (dataSelected, setState: any, state) => {
  let list = JSON.parse(JSON.stringify(state)).map(val => {
    if (val.id == dataSelected.item.id) {
      val.selected = !dataSelected.item.selected;
      val.active = val.selected;
    }
    return val;
  });
  setState(list);
};

/**
 * When user submits the selected element I equalize the lists
 */
export const applySavingToReference = (setReferenceState, state) => {
  const cloneList = JSON.parse(JSON.stringify(state));
  setReferenceState(cloneList);
};

/**
 * When user closes modal I restore the previous list (using the reference)
 */
export const restoreListToReference = (setState, referenceState) => {
  const cloneList = JSON.parse(JSON.stringify(referenceState));
  setState(cloneList);
};

export default function UrstammModalSelect(props: {
  testID: string;
  visible: boolean;
  icon?: UrstammIconProps;
  text: ModalSelectTextOptions;
  selected?: (selected: any) => void;
  closeModal: () => void;
  mandatory?: boolean;
  listJSX: JSX.Element;
  confirmButton?: ModalSelectConfirmButtonOptions;
  modalSize?: ModalSize;
}) {
  return (
    <View style={UrstammStyleModal.centeredView}>
      <Modal animationType="slide" transparent={true} visible={props.visible}>
        <View style={[{ flex: 1, justifyContent: 'center', alignSelf: 'center' }]}>
          <View style={UrstammStyleModal.modalView}>
            {/**Close SortBy Modal */}
            <View style={[styles.modalTopButtonContainer]}>
              <TouchableOpacity
                style={[styles.top_button, UrstammStyle.baseShadow, { alignSelf: 'flex-end' }]}
                onPress={props.closeModal}>
                <View style={[styles.iconCloseContainer]}>
                  <UrstammIcon name={'close'} size={24} color={ColorTheme.mediumGrey} />
                </View>
              </TouchableOpacity>
            </View>

            <View
              style={[
                {
                  maxHeight: props.modalSize?.mainContainerHeight
                    ? Layout.window.height * props.modalSize?.mainContainerHeight
                    : Layout.window.height * 0.65,
                  width: props.modalSize?.mainContainerWidth
                    ? Layout.window.width * props.modalSize?.mainContainerWidth
                    : Layout.window.width * 0.74
                }
              ]}>
              <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                {/**Title */}
                {props.text.textInputTitle && props.text.textInputTitle.length > 0 ? (
                  <View style={[]}>
                    <Text style={[props.text.textStyle]}>
                      {props.text.textInputTitle + getMandatorySymbol(props.mandatory!)}
                    </Text>
                  </View>
                ) : null}
                {props.text.textInputSubTitle && props.text.textInputSubTitle.length > 0 ? (
                  <View>
                    <Text style={[props.text.textSubTitleStyle]}>{props.text.textInputSubTitle}</Text>
                  </View>
                ) : null}

                {/**Divider */}
                <View style={UrstammStyle.divider} />

                {/**List */}
                {props.listJSX ? (
                  <View
                    style={[
                      UrstammStyleLayout.formElementContainerGrw1,
                      {
                        minHeight: props.modalSize?.secondaryContainerHeight
                          ? Layout.window.height * props.modalSize?.secondaryContainerHeight
                          : Layout.window.height * 0.45
                      }
                    ]}>
                    <View style={[UrstammStyle.flex1, { maxHeight: 400 }]}>{props.listJSX}</View>
                  </View>
                ) : null}

                {/**Save */}
                {props.confirmButton?.enabled ? (
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammButtonBase
                      testID={'save_modal'}
                      text={props.confirmButton.text}
                      style={mainGreenButton}
                      disabled={props.confirmButton.disabled}
                      onSubmit={() => props.confirmButton?.submitValue(true)}
                    />
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  modalTopButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-end'
  },
  top_button: {
    flex: 1,
    backgroundColor: ColorTheme.lightGrey,
    borderColor: ColorTheme.mainGreen,
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
    borderWidth: 1,
    zIndex: 2,
    elevation: 2,
    maxWidth: 40,
    alignSelf: 'flex-end'
  },
  iconCloseContainer: {
    minWidth: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  }
});
