/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomProcessorBatchCriteria,
  PageProcessorBatch,
  ProcessorBatch,
  ProcessorBatchDTO,
  Product,
} from '../models';
import {
    CustomProcessorBatchCriteriaFromJSON,
    CustomProcessorBatchCriteriaToJSON,
    PageProcessorBatchFromJSON,
    PageProcessorBatchToJSON,
    ProcessorBatchFromJSON,
    ProcessorBatchToJSON,
    ProcessorBatchDTOFromJSON,
    ProcessorBatchDTOToJSON,
    ProductFromJSON,
    ProductToJSON,
} from '../models';

export interface CreateProcessorBatchCustomRequest {
    processorBatch: ProcessorBatch;
}

export interface GetDSProductsByProcessorBatchRequest {
    id: number;
}

export interface GetPageProcessorBatchesForMyCompanyRequest {
    customProcessorBatchCriteria: CustomProcessorBatchCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface GetProcessorBatchCustomRequest {
    id: number;
}

export interface UpdateProcessorBatchCustomRequest {
    id: number;
    processorBatch: ProcessorBatch;
}

/**
 * 
 */
export class CustomProcessorBatchResourceApi extends runtime.BaseAPI {

    /**
     * Create a new Batch connecting to Company of logged user.
     * Create a Batch
     */
    async createProcessorBatchCustomRaw(requestParameters: CreateProcessorBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorBatch>> {
        if (requestParameters.processorBatch === null || requestParameters.processorBatch === undefined) {
            throw new runtime.RequiredError('processorBatch','Required parameter requestParameters.processorBatch was null or undefined when calling createProcessorBatchCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/processor-batches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorBatchToJSON(requestParameters.processorBatch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorBatchFromJSON(jsonValue));
    }

    /**
     * Create a new Batch connecting to Company of logged user.
     * Create a Batch
     */
    async createProcessorBatchCustom(requestParameters: CreateProcessorBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorBatch> {
        const response = await this.createProcessorBatchCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all products for ProcessorShippingNotes of a Batch.
     * Get all products for ProcessorShippingNotes of a Batch
     */
    async getDSProductsByProcessorBatchRaw(requestParameters: GetDSProductsByProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Set<Product>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDSProductsByProcessorBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/processor-batches/{id}/products`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => new Set(jsonValue.map(ProductFromJSON)));
    }

    /**
     * Get all products for ProcessorShippingNotes of a Batch.
     * Get all products for ProcessorShippingNotes of a Batch
     */
    async getDSProductsByProcessorBatch(requestParameters: GetDSProductsByProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Set<Product>> {
        const response = await this.getDSProductsByProcessorBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a page of Batch belonging to Company of logged User.
     * Get a page of batches for my Company
     */
    async getPageProcessorBatchesForMyCompanyRaw(requestParameters: GetPageProcessorBatchesForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageProcessorBatch>> {
        if (requestParameters.customProcessorBatchCriteria === null || requestParameters.customProcessorBatchCriteria === undefined) {
            throw new runtime.RequiredError('customProcessorBatchCriteria','Required parameter requestParameters.customProcessorBatchCriteria was null or undefined when calling getPageProcessorBatchesForMyCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/processor-batches/company/my`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomProcessorBatchCriteriaToJSON(requestParameters.customProcessorBatchCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageProcessorBatchFromJSON(jsonValue));
    }

    /**
     * Get a page of Batch belonging to Company of logged User.
     * Get a page of batches for my Company
     */
    async getPageProcessorBatchesForMyCompany(requestParameters: GetPageProcessorBatchesForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageProcessorBatch> {
        const response = await this.getPageProcessorBatchesForMyCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Batch identified by given id (must belong to Company of logged user).
     * Get a specific Batch
     */
    async getProcessorBatchCustomRaw(requestParameters: GetProcessorBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorBatchDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProcessorBatchCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/processor-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorBatchDTOFromJSON(jsonValue));
    }

    /**
     * Get Batch identified by given id (must belong to Company of logged user).
     * Get a specific Batch
     */
    async getProcessorBatchCustom(requestParameters: GetProcessorBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorBatchDTO> {
        const response = await this.getProcessorBatchCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update Batch identified by given id (must belong to Company of logged user).
     * Partial update a Batch
     */
    async updateProcessorBatchCustomRaw(requestParameters: UpdateProcessorBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorBatch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProcessorBatchCustom.');
        }

        if (requestParameters.processorBatch === null || requestParameters.processorBatch === undefined) {
            throw new runtime.RequiredError('processorBatch','Required parameter requestParameters.processorBatch was null or undefined when calling updateProcessorBatchCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/processor-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorBatchToJSON(requestParameters.processorBatch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorBatchFromJSON(jsonValue));
    }

    /**
     * Partial update Batch identified by given id (must belong to Company of logged user).
     * Partial update a Batch
     */
    async updateProcessorBatchCustom(requestParameters: UpdateProcessorBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorBatch> {
        const response = await this.updateProcessorBatchCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
