import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import {
  applySavingToReference,
  deepCopyAndAddSelectedParameter,
  restoreListToReference,
  selectElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { resetIncomingProcessorShippingNoteList } from '@redux/features/processor-shipping-note/incomingProcessorShippingNoteSlice';
import { resetOutgoingProcessorShippingNoteList } from '@redux/features/processor-shipping-note/outgoingProcessorShippingNoteSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { setProductPage } from '@redux/features/product/productSlice';
import { RootState } from '@redux/store';
import {
  customProcessorShippingNoteResourceApi,
  customCompanyResourceApi,
  listSize,
  customProcessorBatchResourceApi,
  customProductResourceApi
} from '@services/apis/ApiConfiguration';
import {
  ProcessorShippingNote,
  ProcessorShippingNoteCurrentStateEnum,
  ProcessorShippingNoteDTO,
  Company,
  Product,
  ProductCurrentStateEnum,
  PageCompany,
  ProcessorBatchDTO,
  PageProcessorBatch,
  ProcessorBatch,
  ProcessorShippingNoteDTOCurrentStateEnum,
  ProcessorShippingNoteStateUpdateDTOUpdatedProcessorShippingNoteStateEnumEnum
} from '@services/apis/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { generateSet, idFromProcessorShippingNote, isCompanyMock, sleep } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';
import { setProcessorBatchList, setProcessorBatchPage } from '@redux/features/processor-batch/processorBatchSlice';
import { createOnlyIdProcessorBatch, createOnlyIdCompany, createOnlyIdProduct } from '../../utils/classes/UrstammClassMapper';
import { ExternalCompany } from '@services/apis/generated/models/ExternalCompany';
import ProcessorShippingNoteDetailsView from '@components/views/processor-shipping-note/ProcessorShippingNoteDetailsView';


export default function ProcessorShippingNoteDetailsContainer({
  navigation,
  route
}: RootStackScreenProps<'ProcessorShippingNoteDetails'>) {
  const rdxProcessorBatchPage = useSelector((state: RootState) => state.persistedReducer.processorBatch.page);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);

  const rdxProductPage = useSelector((state: RootState) => state.persistedReducer.product.page);
  const rdxProductTotalPages = useSelector((state: RootState) => state.persistedReducer.product.totalPages);
  const rdxProcessorBatchTotalPages = useSelector((state: RootState) => state.persistedReducer.processorBatch.totalPages);
  const dispatch = useDispatch();

  const [pktList, setPktList] = useStateWithCallbackLazy<any>([]);
  const [pktListReference, setPktListReference] = useState<any>([]);

  const [processorBatchSelected, setProcessorBatchSelected] = useState<any>();
  const [processorSelected, setProcessorSelected] = useState<Company>();
  const [processorBatchListState, setProcessorBatchListState] = useState<any>();
  const [processorBatchListReference, setProcessorBatchListReference] = useState<any>();
  const [processorList, setProcessorList] = useState<Company[]>([]);
  //const [productListState, setProductListState] = useState<Product[]>([]);
  const [processorShippingNoteList, setProcessorShippingNoteList] = useStateWithCallbackLazy<ProcessorShippingNote[]>([]);
  const [processorShippingNoteSelected, setProcessorShippingNoteSelected] = useState<ProcessorShippingNote>();
  const [processorShippingNoteDTO, setProcessorShippingNoteDTO] = useState<any>(() => {});
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [productList, setProductList] = useStateWithCallbackLazy<any>(() => {});
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  const outgoing = useMemo(() => {
    return !!route?.params?.outgoing;
  }, [route?.params?.outgoing])

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: undefined,
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    customProcessorBatchResourceApi
      .getPageProcessorBatchesForMyCompany({
        customProcessorBatchCriteria: { currentState: {} },
        page: rdxProcessorBatchPage,
        size: 200
      })
      .then((processorBatchList: PageProcessorBatch) => {
        dispatch(changeLoaderStatus(false));
        if (processorBatchList) {
          dispatch(setProcessorBatchList(processorBatchList.content));
          let list: ProcessorBatchDTO[] = JSON.parse(JSON.stringify(processorBatchList.content));
          list.sort((a, b) => {
            if (a?.creationDate && b?.creationDate) {
              return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
            }
            return a?.creationDate ? -1 : 1;
          });
          setProcessorShippingNoteList(route.params?.processorShippingNoteList, () => {
            deepCopyAndAddSelectedParameter(list, setProcessorBatchListState, setProcessorBatchListReference);
          });
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  }, []);

  /**
   * Filtra la lita di prodotti inseribili all'interno dello shipping note
   * */
  useEffect(() => {
    if (processorBatchSelected && processorSelected && processorShippingNoteDTO && outgoing) {
      customProductResourceApi
        .getProductsByBatch({
          batchId: processorBatchSelected.id
        })
        .then((productList: Product[]) => {
          productList = productList.filter(
            item =>
              item.currentState == ProductCurrentStateEnum.Closed &&
              !item.project &&
              item.sumCubage &&
              (item.processor?.id == processorSelected?.id ||
                (isCompanyMock(item.processor) && isCompanyMock(processorSelected)))
          );
          setProductsSelected(processorShippingNoteDTO, productList);
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    }
  }, [processorBatchSelected, processorSelected, processorShippingNoteDTO, outgoing]);

  useEffect(() => {
    if (route.params?.processorShippingNoteSelected) {
      setProcessorShippingNoteSelected(route.params?.processorShippingNoteSelected);
      getProcessorShippingNoteDTObyProcessorShippingNoteId(route.params?.processorShippingNoteSelected);
    }
  }, [route.params?.processorShippingNoteSelected]);

  useEffect(() => {
    getAllCompanyProcessorConnectedToMe();
  }, []);

  const getProcessorShippingNoteDTObyProcessorShippingNoteId = (processorShippingNote: ProcessorShippingNote) => {
    customProcessorShippingNoteResourceApi
      .getProcessorShippingNoteCustom({ id: processorShippingNote.id! })
      .then((processorShippingNoteDTO: ProcessorShippingNoteDTO) => {
        if (processorShippingNoteDTO) {
          setProcessorBatchSelected(processorShippingNoteDTO.outgoingBatch);
          setProcessorSelected(processorShippingNoteDTO.processor);
          setProcessorShippingNoteDTO(processorShippingNoteDTO);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  /**
   *
   * @param processorShippingNoteDTO
   * @param list with custom value (selected: boolean)
   */
  const setProductsSelected = (processorShippingNoteDTO: ProcessorShippingNoteDTO, list) => {
    let productsSelected: Product[] = JSON.parse(JSON.stringify(Array.from(processorShippingNoteDTO.incomingProducts || new Set()))); //Copy Product from processorShippingNoteDTO (Set)
    // Deselect already selected product BUT sent in shipping note (not usable anymore)
    productsSelected = productsSelected.filter(p => p.currentState !== ProductCurrentStateEnum.SentInShippingNote);
    if (productsSelected.length > 0) {
      let copyList = JSON.parse(JSON.stringify(list));
      productsSelected.forEach((productsSel: Product) => {
        copyList = selectProducts(productsSel, copyList, true);
      });
      //if i haven't Products saved in the processorShippingNote I set the default list with (selected: false)
    } else {
      setPktList(list, () => {});
      setPktListReference(list);
    }
  };

  const selectProducts = (data, list, setReference?: boolean) => {
    let listCopy = JSON.parse(JSON.stringify(list));
    listCopy.forEach(val => {
      if (val.id == data.id) {
        val.selected = !data.selected;
      }
    });
    setPktList(listCopy, () => {});
    setReference ? setPktListReference(listCopy) : null;
    return listCopy;
  };

  /**
   * When user closes modal I restore the previous list (using the reference)
   */
  const restoreProductList = () => {
    const cloneList = JSON.parse(JSON.stringify(pktListReference));
    setPktList(cloneList, () => {});
  };

  const resetList = () => {
    route?.params?.outgoing ? dispatch(resetOutgoingProcessorShippingNoteList()) : dispatch(resetIncomingProcessorShippingNoteList());
  }

  const updateProcessorShippingNote = (processorShippingNote: ProcessorShippingNoteDTO, closingProcessorShippingNote?: boolean) => {
    let productsSet: Set<Product> = productList ? new Set(generateSet(productList)) : new Set();
    let selectedProductsList = pktList.filter(p => p.selected).map(p => createOnlyIdProduct(p));

    dispatch(changeLoaderStatus(true));

    let processorShippingNoteObj: ProcessorShippingNote = {
      id: processorShippingNoteSelected?.id!,
      name: processorShippingNote.name!,
      creationDate: processorShippingNote.creationDate,
      currentState: closingProcessorShippingNote ? ProcessorShippingNoteCurrentStateEnum.Sent : ProcessorShippingNoteCurrentStateEnum.InProgress,
      comment: processorShippingNote.comment!,
      outgoingBatch: createOnlyIdProcessorBatch(processorShippingNote.outgoingBatch),
      processor: createOnlyIdCompany(processorShippingNote.processor),
      incomingProducts: selectedProductsList
    };

    customProcessorShippingNoteResourceApi
      .updateProcessorShippingNoteCustom({ id: processorShippingNoteSelected?.id!, processorShippingNote: processorShippingNoteObj })
      .then((processorShippingNote: ProcessorShippingNote) => {
        dispatch(changeLoaderStatus(false));
        if (processorShippingNote) {
          resetList();
          route?.params?.outgoing ? UrstammNavigationHelper.navigateToOutgoingProcessorShippingNoteList(navigation, true) : UrstammNavigationHelper.navigateToIncomingProcessorShippingNoteList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const goBack = async (): Promise<void> => {
    resetList();
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    route?.params?.outgoing ? UrstammNavigationHelper.navigateToOutgoingProcessorShippingNoteList(navigation, true) : UrstammNavigationHelper.navigateToIncomingProcessorShippingNoteList(navigation, true);
  };

  const getMoreProductsItems = (): void => {
    if (rdxProductPage < rdxProductTotalPages - 1) {
      dispatch(setProductPage(rdxProductPage + 1));
    }
  };

const closeProcessorShippingNote = (processorShippingNoteDTO: ProcessorShippingNoteDTO, externalCompany?: ExternalCompany) => {
    customProcessorShippingNoteResourceApi
      .updateProcessorShippingNoteStateCustom({
        id: processorShippingNoteDTO.id!,
        processorShippingNoteStateUpdateDTO: {
          updatedProcessorShippingNoteStateEnum: ProcessorShippingNoteStateUpdateDTOUpdatedProcessorShippingNoteStateEnumEnum.Sent,
          name: externalCompany?.name,
          streetName: externalCompany?.streetName,
          streetNumber: externalCompany?.streetNumber,
          phoneNumber: externalCompany?.phoneNumber,
          email: externalCompany?.email,
          city: externalCompany?.city,
          zipCode: externalCompany?.zipCode
        }
      })
      .then((processorShippingNote: ProcessorShippingNote) => {
        dispatch(changeLoaderStatus(false));
        if (processorShippingNote) {
          resetList();
          route?.params?.outgoing ? UrstammNavigationHelper.navigateToOutgoingProcessorShippingNoteList(navigation, true) : UrstammNavigationHelper.navigateToIncomingProcessorShippingNoteList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const showProductDetails = (product?: Product) => {
    UrstammNavigationHelper.navigateToProductDetails(
      navigation,
      product?.processorBatch,
      product,
      {
        readOnly: true,
        returnTo: 'back'
      },
    )
  }
  
  /*const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setProductListSort(orderSelected));
    setShowSortBy(false);
  };*/

  const navigateToProductRegistration = () => {
    UrstammNavigationHelper.navigateToProductRegistration(navigation, processorShippingNoteDTO);
  };

  const processorBatchSelectID = processorBatch => {
    setProcessorBatchSelected(processorBatch);
  };

  const processorSelectID = processor => {
    setProcessorSelected(processor);
  };

  const getMoreProcessorBatchItems = (): void => {
    if (rdxProcessorBatchPage < rdxProcessorBatchTotalPages - 1) {
      dispatch(setProcessorBatchPage(rdxProcessorBatchPage + 1));
    }
  };

  /**
   * Setting processor list, it used for "Registration" and "Detail" Pages.
   * @param order
   */
  const getAllCompanyProcessorConnectedToMe = (order?: ButtonSelect[]) => {
    let sortBy = order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['name,desc'];
    dispatch(changeLoaderStatus(true));

    customCompanyResourceApi
      .getCompaniesConnectedToMe({
        sort: sortBy,
        customCompanyCriteria: {},
        page: 0,
        size: listSize
      })
      .then((list: PageCompany) => {
        dispatch(changeLoaderStatus(false));

        if (list && list.content?.length) {
          list.content.push({ name: i18n.t('views.processor_shipping_note.no_urstamm_processor'), id: -200 });
          setProcessorList(list.content);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const updateState = (processorShippingNoteDTO: ProcessorShippingNoteDTO, accepted: boolean) => {
    customProcessorShippingNoteResourceApi.updateProcessorShippingNoteStateCustom({
      id: processorShippingNoteDTO.id!,
      processorShippingNoteStateUpdateDTO: {
        updatedProcessorShippingNoteStateEnum: accepted ? ProcessorShippingNoteDTOCurrentStateEnum.Accepted : ProcessorShippingNoteDTOCurrentStateEnum.Rejected 
      }
    }).then(processorShippingNote => {
      goBack();
    })
    .catch(async error => {
      dispatch(changeLoaderStatus(false));
      let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
      let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
      AlertHelper.showSimpleAlert('Error', errorJson);
    });
  }

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      submitBottomRight={navigateToProductRegistration}
      title={
        <UrstammTitle
          testID={'processorShippingNote_title'}
          text={
            outgoing
              ? i18n.t('generics.outgoing_goods')
              : i18n.t('generics.incoming_goods')
            }
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'trunk_details_subtitle'}
          text={String(idFromProcessorShippingNote(processorShippingNoteSelected))}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={[{ title: i18n.t('generics.name'), value: processorShippingNoteSelected?.name!, truncateValue: 30 }]}
      view={
        <ProcessorShippingNoteDetailsView
          navigation={navigation}
          processorShippingNoteSelectedDTO={processorShippingNoteDTO!}
          moreItems={getMoreProductsItems}
          userExtendedMe={rdxUserExtendedMe}
          processorBatchProps={{
            processorBatchList: processorBatchListState,
            processorBatchSelected: processorBatchSelected => selectElementFormList(processorBatchSelected, setProcessorBatchListState, processorBatchListState),
            moreItems: getMoreProcessorBatchItems,
            resetProcessorBatchSelected: () => restoreListToReference(setProcessorBatchListState, processorBatchListReference),
            processorBatchSelectedID: processorBatch => processorBatchSelectID(processorBatch)
          }}
          processorProps={{
            getAll: getAllCompanyProcessorConnectedToMe,
            processorList: processorList,
            processorSelectedID: processor => processorSelectID(processor)
          }}
          productListProps={{
            productSelected: showProductDetails,
          }}
          updateProcessorShippingNote={(processorShippingNoteDTO: ProcessorShippingNoteDTO) => updateProcessorShippingNote(processorShippingNoteDTO)}
          processorBatchList={processorBatchListState}
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          processorList={processorList}
          closingProcessorShippingNote={(processorShippingNoteDTO: ProcessorShippingNoteDTO, externalCompany?: ExternalCompany) => closeProcessorShippingNote(processorShippingNoteDTO, externalCompany)}
          customProductList={pktList}
          confirmProductSelected={() => applySavingToReference(setPktListReference, pktList)}
          resetProductSelected={restoreProductList}
          productSelected={pktSelected => selectProducts(pktSelected.item, pktList, false)}
          processorShippingNoteList={processorShippingNoteList}
          outgoing={route?.params?.outgoing} 
          updateState={(processorShippingNoteDTO: ProcessorShippingNoteDTO, accepted: boolean) => updateState(processorShippingNoteDTO, accepted)} />
      }
    />
  );
}
